import React, { useContext, useRef, useState, useEffect } from "react";
import { UserContext } from "../Context/UserContext";
import { useDropzone } from "react-dropzone";
import axios from "axios";
import AWS from "aws-sdk";
//import useFetchUploadedFiles from "../hooks/useFetchUploadedFiles";

const FilesUpload = ({fetchUploadedFiles}) => {
  const [isFileUploadVisible, setIsFileUploadVisible] = useState(false);
  const [files, setFiles] = useState([]);
  const [previews, setPreviews] = useState([]);
  const [fileType, setFileType] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errors, setErrors] = useState([]);
  const previewsRef = useRef(previews);

  const { users } = useContext(UserContext);
  const token = users?.token;
  const { enquiresIdentity } = useContext(UserContext);

  

  const filesList = [
    { id: 1, name: "Prescription" },
    { id: 2, name: "Medical Report" },
    { id: 3, name: "Lab Results" },
    { id: 4, name: "MRI Scan" },
  ];


  const onDrop = (acceptedFiles) => {
    const uniqueFiles = acceptedFiles.filter(
      (newFile) =>
        !files.some(
          (existingFile) =>
            existingFile.name === newFile.name &&
            existingFile.lastModified === newFile.lastModified
        )
    );

    if (uniqueFiles.length === 0) {
      // toast.error("files are already uploaded.");
      console.log("files are already uploaded.");
      return;
    }

    const newFiles = [...files, ...uniqueFiles];
    setFiles(newFiles);

    const initialOptions = uniqueFiles.reduce((file, index) => {
      file[files.length + index] = "";
      return file;
    }, {});

    setFileType((prevOptions) => ({ ...prevOptions, ...initialOptions }));

    const newPreviews = uniqueFiles.map((file) => {
      if (file.type.startsWith("image/")) {
        return URL.createObjectURL(file);
      } 
      else if (file.type === "application/pdf") {
        return "pdf-icon";
      } else if (
        file.type === "application/msword" ||
        file.type ===
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
      ) {
        return "word-icon";
      }
      return null;
    });

    setPreviews((prevPreviews) => {
      const updatedPreviews = [...prevPreviews, ...newPreviews];
      previewsRef.current = updatedPreviews;
      return updatedPreviews;
    });
  };

  useEffect(() => {
    return () => {
      previewsRef.current.forEach((preview) => {
        if (preview) URL.revokeObjectURL(preview);
      });
    };
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      "image/jpeg": [".jpeg", ".jpg"],
      "image/png": [".png"],
      "image/gif": [".gif"],
      "application/pdf": [".pdf"],
      "application/msword": [".doc"],
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        [".docx"],
    },
    multiple: true,
  });

  const handleDropdownChange = (index, value) => {
    setFileType((prevOptions) => ({
      ...prevOptions,
      [index]: value,
    }));

    const updatedErrors = [...errors];
    updatedErrors[index] = value ? "" : "Please select a file type.";
    setErrors(updatedErrors);
  };

  const uploadFile = async (filesToUpload) => {
    const s3 = new AWS.S3({
      accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
      secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
      region: process.env.REACT_APP_AWS_REGION,
    });

    setIsSubmitting(true);

    try {
      const bucketName = "clinicmanagement";
      const uploadPromises = filesToUpload.map((file) => {
        const key = `${enquiresIdentity}/${file.file.name}`;
        const uploadParams = { Bucket: bucketName, Key: key, Body: file.file };

        return s3
          .upload(uploadParams)
          .promise()
          .then((uploadResult) => {
            addNewFile(uploadResult.Location, file);
            return uploadResult;
          });
      });

      await Promise.all(uploadPromises);
      setIsSubmitting(false);
      // toast.success("uploded successfully");

      // Cleanup previews before resetting
      previewsRef.current.forEach((preview) => {
        if (preview) URL.revokeObjectURL(preview);
      });

      setFiles([]);
      setFileType({});
      setPreviews([]);
      previewsRef.current = [];

      //onFileUpload();
      fetchUploadedFiles(enquiresIdentity)
    } catch (error) {
      console.error("Error uploading file:", error);
      setIsSubmitting(false);
    }
  };

  const handleRemoveFile = (index) => {
    const updatedFiles = [...files];
    const updatedPreviews = [...previews];
    const updatedErrors = [...errors];

    // Remove the file and its associated preview and error
    updatedFiles.splice(index, 1);
    updatedPreviews.splice(index, 1);
    updatedErrors.splice(index, 1);

    setFiles(updatedFiles);
    setPreviews(updatedPreviews);
    setErrors(updatedErrors);

    // Revoke the object URL if it exists
    if (
      previews[index] &&
      previews[index] !== "pdf-icon" &&
      previews[index] !== "word-icon"
    ) {
      URL.revokeObjectURL(previews[index]);
    }
  };

  const addNewFile = async (uploadedFileUrl, file) => {
    try {
      const payload = {
        identity: enquiresIdentity,
        doc_url: uploadedFileUrl.split("?")[0],
        file_type: file.fileType,
        file_name: file?.file?.name,
        added_by_id: users?.id,
        added_by_name: users?.first_name,
      };

      await axios.post(
        `${process.env.REACT_APP_NEW_API_URL}/save-s3-document`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    } catch (error) {
      console.error("Upload error:", error);
    }
  };

  const handleNewFileSubmission = () => {
    if (files.length === 0) {
      // toast.error("Please select files to upload.");
      alert("Please select files to upload.");
      return;
    }

    const newErrors = files.map((_, index) =>
      fileType[index] ? "" : "Please select a file type."
    );
    setErrors(newErrors);

    const hasErrors = newErrors.some((error) => error !== "");
    if (hasErrors) {
      return;
    }

    const filesToUpload = files.map((file, index) => ({
      file,
      fileType: fileType[index],
    }));

    uploadFile(filesToUpload);
  }

  return (
    <div>
      <div className="mb-4">
        <button
          className="w-full p-3 bg-slate-200 text-left font-semibold text-xl rounded-lg focus:outline-none flex justify-between items-center"
          onClick={() => setIsFileUploadVisible(!isFileUploadVisible)}
        >
          Document Upload
          <span>
            {isFileUploadVisible ? (
              <svg
                className="w-6 h-6 text-gray-800"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m16 14-4-4-4 4"
                />
              </svg>
            ) : (
              <svg
                className="w-6 h-6 text-gray-800"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m8 10 4 4 4-4"
                />
              </svg>
            )}
          </span>
        </button>
      </div>
      {isFileUploadVisible && (
        <div>
          <div
            {...getRootProps()}
            className={`border-2 border-dashed rounded-lg p-6 mb-4 ${
              isDragActive ? "border-blue-500 bg-blue-50" : "border-gray-300"
            }`}
          >
            <input {...getInputProps()} />
            {isDragActive ? (
              <p className="text-blue-500">Drop the files here ...</p>
            ) : (
              <p className="text-gray-500">
                Drag and drop some files here, or click to select files
              </p>
            )}
          </div>

          {files.length > 0 && (
            <div className="mb-2">
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
                {files.map((file, index) => (
                  // <div key={index} className="border rounded-lg p-4">
                  <div key={index} className="border rounded-lg p-4 relative">
                    {/* Remove File Icon */}
                    <button
                      className="absolute -top-3 -right-3 text-red-500 bg-white rounded-full p-1 shadow-md hover:bg-red-100"
                      onClick={() => handleRemoveFile(index)}
                    >
                      <svg
                        className="w-4 h-4"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth={2}
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    </button>
                    <p className="text-sm font-semibold mb-2 truncate">
                      {file.name}
                    </p>
                    {/* {previews[index] ? (
                      <img
                        src={previews[index]}
                        alt={`Preview of ${file.name}`}
                        className="w-full h-32 object-cover rounded-md mb-2"
                      />
                    ) : (
                      <div className="w-full h-32 bg-gray-100 rounded-md mb-2 flex items-center justify-center">
                        <span className="text-gray-400">
                          No preview available
                        </span>
                      </div>
                    )} */}

                    {previews[index] === "pdf-icon" ? (
                      <div className="w-full h-32 flex items-center justify-center bg-gray-100 mb-2">
                        <img
                          src="https://upload.wikimedia.org/wikipedia/commons/8/87/PDF_file_icon.svg"
                          alt="PDF Icon"
                          className="w-12 h-12"
                        />
                      </div>
                    ) : previews[index] === "word-icon" ? (
                      <div className="w-full h-32 flex items-center justify-center bg-gray-100 mb-2">
                        <img
                          src="https://logodownload.org/wp-content/uploads/2018/10/word-logo-8.png"
                          alt="Word Icon"
                          className="w-12 h-12"
                        />
                      </div>
                    ) : (
                      <img
                        src={previews[index]}
                        alt={file.name}
                        className="w-full h-32 object-cover rounded-md mb-2"
                      />
                    )}
                    <select
                      className="w-full rounded-lg bg-gray-100 text-sm focus:ring-blue-500 focus:border-blue-500 p-2"
                      onChange={(e) =>
                        handleDropdownChange(index, e.target.value)
                      }
                      value={fileType[index]}
                    >
                      <option value="">Choose File Type</option>
                      {filesList.map((fileType) => (
                        <option key={fileType.id} value={fileType.name}>
                          {fileType.name}
                        </option>
                      ))}
                    </select>
                    {errors[index] && (
                      <p className="text-red-500 text-xs mt-1">
                        {errors[index]}
                      </p>
                    )}
                  </div>
                ))}
              </div>
              <button
                className="mt-4 px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
                onClick={handleNewFileSubmission}
                disabled={isSubmitting}
              >
                {isSubmitting ? (
                  <span className="flex items-center">
                    <svg
                      className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                    Uploading...
                  </span>
                ) : (
                  "Upload Files"
                )}
              </button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default FilesUpload;
