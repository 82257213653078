import React, { useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";

import { UserContext } from "../Context/UserContext";
import Enquires from "../assets/enquires.png";
import Users from "../assets/users.png";

const Admin = () => {
  const { users, setUsers } = useContext(UserContext);
  const token = users?.token;

  const navigate = useNavigate();

  useEffect(() => {
    if (!token) {
      navigate("/login");
    }
  }, [token, navigate]);

  const handleLogout = () => {
    setUsers(null); // Clear user data from context
    navigate("/login");
  };

  return (
    <div className=" min-h-screen  bg-[#F9F8F8]">
      {/* Header */}
      <header className="bg-[#E9F0FF]   py-4 px-6 flex justify-between items-center  font-poppins">
        <h1 className="text-xl font-semibold ">Admin</h1>
        <button
          onClick={handleLogout}
          className="text-lg hover:underline focus:outline-none"
        >
          Logout
        </button>
      </header>

      {/* Main content */}
      <main>
        <div className="max-w-2xl mx-auto p-20">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 md:gap-24 lg:gap-40 font-poppins font-medium">
            {/* Enquiries Button */}

            <button
              onClick={() => navigate("/admin/patients")}
              className="flex items-center justify-center gap-3 p-4 bg-white rounded-lg border border-[#999999] shadow-sm hover:bg-gray-50 transition-colors"
            >
              <img src={Enquires} alt="enquires img" className="w-8 h-8" />
              <span className="text-gray-800 font-medium">PATIENTS</span>
            </button>

            {/* Users Button */}

            <button
              onClick={() => navigate("/admin/users")}
              className="flex items-center justify-center gap-3 p-6 bg-white rounded-lg border border-[#999999] shadow-sm hover:bg-gray-50 transition-colors"
            >
              <img src={Users} alt="enquires img" className="w-8 h-7" />

              <span className="text-gray-800 font-medium">USERS</span>
            </button>
          </div>
        </div>
      </main>
    </div>
  );
};

export default Admin;

