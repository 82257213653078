

import React, { useState } from "react";
import { useNavigate } from "react-router";

const menuItems = [
  { label: "Patient", submenu: true },
  { label: "User", submenu: true },
  { label: "Clinic", submenu: true },
  { label: "Doctor", submenu: true },
];

const Sidebar = ({ isMenuOpen }) => {
  const navigate = useNavigate();
  const [selectedItem, setSelectedItem] = useState(null);

  const handleMenuClick = (label, submenu) => {
    if (submenu) {
      setSelectedItem((prev) => (prev === label ? null : label));
    }
  };

  return (
    <div
      className={`transition-all duration-300 bg-gray-900 text-white z-10 ${
        isMenuOpen ? "w-40 opacity-100" : "w-0 opacity-0"
      }`}
      aria-expanded={isMenuOpen}
    >
      <div className="p-4 ">
        <h2 className="text-xl font-semibold">Menu</h2>
        <ul className="mt-6">
          {menuItems.map(({ label, submenu }) => (
            <li key={label}>
              <div
                className={`cursor-pointer hover:bg-gray-700 p-2 rounded my-2 ${
                  selectedItem === label ? "bg-gray-700" : ""
                }`}
                onClick={() => handleMenuClick(label, submenu)}
                role="menuitem"
              >
                {label}
              </div>

              {/* Expandable Sections */}
              {submenu && selectedItem === label && (
                <ul className="ml-4">
                  <li
                    className="cursor-pointer hover:bg-gray-700 p-2 rounded text-sm"
                    onClick={() =>
                      navigate(`/admin/${label.toLowerCase()}s`)
                    }
                  >
                    List of {label}
                  </li>
                  <li
                    className="cursor-pointer hover:bg-gray-700 p-2 rounded text-sm mt-2"
                    onClick={() =>
                      navigate(`/admin/add-${label.toLowerCase()}`)
                    }
                  >
                    Add New {label}
                  </li>
                </ul>
              )}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;




