import React, { useContext, useEffect, useRef, useState } from "react";
import { UserContext } from "../Context/UserContext";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";

const AddNewPromptSummaryPopup = ({
  closePrompt,
  fetchAiSummaries,
  file,
  // records,
}) => {
  const { file_type, file_name } = file;
  const [newPrompt, setNewPrompt] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  //const [promptData, setPromptData] = useState([]);
  const [latestPromtData, setLatestPromtData] = useState([]);

  const scrollRef = useRef(null);

  const { users } = useContext(UserContext);
  const navigate = useNavigate();
  const { id } = useParams();
  const token = users?.token;
  const name = users?.first_name;
  const roles = { 1: "Admin", 2: "Doctor", 3: "Patient" };
  const role = roles[users?.role_id];

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
    }
  }, [isSubmitted, latestPromtData]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!newPrompt.trim()) return; // Prevent empty prompts

    setIsSubmitted(true);

    // Add the new prompt with "Loading..." for the summary
    const tempPrompt = {
      prompt: newPrompt,
      summary: "fetching insights...",
    };

    setLatestPromtData((prev) => [...prev, tempPrompt]);
    setNewPrompt(""); // Clear the input field

    try {
      const payload = {
        file_id: id,
        prompt: newPrompt,
        created_by: `${name}-${role}`,
      };

      const response = await axios.post(
        `${process.env.REACT_APP_NEW_API_URL}/generate-ai-user-prompt`,
        payload,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      console.log(response);
      console.log(response?.data?.allSummaries?.data?.dayWiseRecords);

      if (response?.data?.status === 200) {
        //   const newData = response?.data?.data[0];

        // //Update the latest prompt's summary
        // setLatestPromtData((prev) =>
        //   prev.map((data, index) =>
        //     index === prev.length - 1
        //       ? { ...data, summary: newData.summary }
        //       : data
        //   )
        // );

        const dayWiseRecords =
          response?.data?.allSummaries?.data?.dayWiseRecords; 

        if (dayWiseRecords) {
          // Flatten and sort records by date (ascending order)
          const allRecords = Object.entries(dayWiseRecords)
            .flatMap(([date, records]) => {
              console.log(records,"rr"); 
              return records.map((record) => ({
                date,
                summary: record.summary,
                created: record.created_at,
              }));
            })
         console.log(allRecords)
          // Get the latest summary (last record after sorting)
          const latestSummary =
            allRecords.length > 0
              ? allRecords[0].summary
              : null;

          console.log(latestSummary, "ll");

          if (latestSummary) {
            // Update the latest prompt's summary
            setLatestPromtData((prev) =>
              prev.map((data, index) =>
                index === prev.length - 1
                  ? { ...data, summary: latestSummary }
                  : data
              )
            );
          }
        }
        fetchAiSummaries(file);
      }
    } catch (error) {
      console.log("error", error);
      if (error?.response?.status === 400) {
        navigate("/login");
      }
    } finally {
      setIsSubmitted(false);
    }
  };

  return (
    <div className="fixed inset-0 flex justify-center items-center bg-black/50  font-poppins min-h-screen">
      <div className="bg-white w-full mx-10 h-[90vh] p-4 rounded-2xl shadow-xl overflow-hidden flex flex-col my-5">
        <div className="w-full flex justify-between items-center">
          {/* <p className="font-medium text-lg ">Generate New Insights for {file_name} - {file_type} </p> */}
          <div>
            <p className="text-lg font-semibold text-gray-800 ">
              <span className="text-gray-700">Generate New Insights for </span>
              <span className="font-bold text-black underline">
                {file_name}
              </span>
              <span className="text-gray-500"> - {file_type}</span> 
            </p>
          </div>
          <button onClick={() => closePrompt()}>
            <svg
              className="w-6 h-6 text-gray-800 "
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="m15 9-6 6m0-6 6 6m6-3a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
              />
            </svg>
          </button>
        </div>
        <div
          className="flex-grow w-full mt-4 overflow-y-auto scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-gray-200"
          ref={scrollRef}
        >
          {latestPromtData.length > 0 && (
            <div>
              {" "}
              {latestPromtData.map((data, index) => (
                <div key={index}>
                  <div className="flex justify-end px-2 mb-2">
                    <div className="bg-slate-200 rounded-md p-2 self-end w-fit max-w-[75%]">
                      <p className="">{data?.prompt}</p>
                    </div>
                  </div>
                  <div className="mt-4 mb-4  mr-20 flex justify-start px-2">
                    <div className="  p-2 bg-slate-300   rounded-md break-words leading-relaxed">
                      <p>{data?.summary}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>

        <div className="mt-auto  pr-4">
          <form onSubmit={handleSubmit} className="w-full">
            <div className="relative w-full ">
              <textarea
                placeholder="Type your prompt here..."
                className="w-full px-4 py-2 pr-8 border border-slate-200 rounded-2xl focus:outline-none focus:ring-1 focus:ring-slate-300 resize-none h-20 bg-slate-100 "
                onChange={(e) => setNewPrompt(e.target.value)}
                value={newPrompt}
                onKeyDown={(e) => {
                  if (e.key === "Enter" && !e.shiftKey) {
                    e.preventDefault();
                    handleSubmit(e);
                  }
                }}
              />
              <button
                type="submit"
                className={`absolute right-6 bottom-3 text-blue-500 hover:text-blue-700 ${
                  isSubmitted ? "cursor-not-allowed" : ""
                }`}
                disabled={isSubmitted}
              >
                ➤
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddNewPromptSummaryPopup;
