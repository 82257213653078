import React, { useContext } from "react";
import { useNavigate } from "react-router";
import { UserContext } from "../Context/UserContext";

const Header = ({ toggleMenu }) => {
  const { setUsers } = useContext(UserContext);
  const navigate = useNavigate();

  const handleLogout = () => {
    setUsers(null);
    navigate("/login");
  };

  return (
    <div className="bg-[#E9F0FF]  h-[10vh] px-4 flex items-center justify-between w-full rounded-t-lg font-poppins">
      <svg
        className="w-8 h-8 text-black cursor-pointer"
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        fill="none"
        viewBox="0 0 24 24"
        onClick={toggleMenu}
      >
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeWidth="2"
          d="M5 7h14M5 12h14M5 17h14"
        />
      </svg>

      <h3 className=" text-black cursor-pointer" onClick={handleLogout}>
        Logout
      </h3>
    </div>
  );
};

export default Header;
