import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router";
import axios from "axios";
import { toast } from "react-toastify";
import cognismilesLogo from "../assets/cognismiles-logo.png";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import useSpecializations from "../hooks/useSpecialization";
import useClinicList from "../hooks/useClinicList";
import Select from "react-select";

const Signup = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    postalCode: "",
    phoneNumber: "",
    email: "",
    password: "",
    confirmPassword: "",
    clinic: [],
    selectedSpecializations: [],
    role: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [error, setError] = useState({
    confirmPassword: "",
    role: "",
    captcha: "",
    signUp: "",
    otp: "",
  });
  const [otpSent, setOtpSent] = useState(false);
  const [formDisabled, setFormDisabled] = useState(false);
  const [maskedEmail, setMaskedEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [resendOtpMessage, setResendOtpMessage] = useState("");
  const otpSectionRef = useRef(null);

  // Resend OTP Controls
  const [resendTimer, setResendTimer] = useState(60);
  const [isResendDisabled, setIsResendDisabled] = useState(true);

  //specilization and clinics data
  const { specializations } = useSpecializations();
  const { clinicsList } = useClinicList();

  const { executeRecaptcha } = useGoogleReCaptcha();
  const navigate = useNavigate();

  const roleDetails = [
    { roleID: 1, roleName: "ADMIN" },
    { roleID: 2, roleName: "DOCTOR" },
    { roleID: 3, roleName: "PATIENT" },
  ];

  useEffect(() => {
    let timer;
    if (isResendDisabled && otpSent) {
      timer = setInterval(() => {
        setResendTimer((prev) => {
          if (prev <= 1) {
            clearInterval(timer);
            setIsResendDisabled(false);
            return 60;
          }
          return prev - 1;
        });
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [isResendDisabled, otpSent]);

  // Function to mask the email
  const maskEmail = (email) => {
    if (!email) return "";

    const [localPart, domain] = email.split("@");
    if (!domain) return email;

    const firstTwo = localPart.slice(0, 2);
    const lastTwo = localPart.slice(-2);

    const maskedLocal =
      localPart.length > 6
        ? firstTwo + localPart.slice(2, -2).replace(/./g, "X") + lastTwo
        : localPart[0] +
        localPart.slice(1, -1).replace(/./g, "X") +
        localPart.slice(-1);

    return `${maskedLocal}@${domain}`;
  };

  useEffect(() => {
    if (formData?.email) {
      setMaskedEmail(maskEmail(formData.email));
    }
  }, [formData?.email]);

  useEffect(() => {
    if (otpSent && otpSectionRef.current) {
      otpSectionRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [otpSent]);

  const handleChange = (name, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: value.replace(" ", ""),
    }));
    if (name === "confirmPassword" && formData.password !== value) {
      setError((prevError) => ({
        ...prevError,
        confirmPassword: "Passwords do not match",
      }));
    } else if (name === "confirmPassword") {
      setError((prevError) => ({ ...prevError, confirmPassword: "" }));
    }
  };

  const handleRegistration = async () => {
    if (formData.password !== formData.confirmPassword) {
      setError((prev) => ({
        ...prev,
        confirmPassword: "Passwords do not match",
      }));
      return;
    }

    const selectedRole = roleDetails.find(
      (role) => role.roleName.toLowerCase() === formData.role.toLowerCase()
    );
    if (!selectedRole) {
      setError((prev) => ({ ...prev, role: "Invalid role selected" }));
      return;
    }

    if (!executeRecaptcha) {
      setError((prevError) => ({
        ...prevError,
        captcha: "reCAPTCHA is not available. Please try again later",
      }));
      return;
    }

    try {
      const captchaToken = await executeRecaptcha("login");
      console.log("reCAPTCHA token:", captchaToken);
      if (!captchaToken) {
        setError((prevError) => ({
          ...prevError,
          captcha: "Failed to generate reCAPTCHA token. Please try again.",
        }));
        return;
      }

      const tokenPayload = {
        token: captchaToken,
      };
      const captchaUrl = `${process.env.REACT_APP_NEW_API_URL}/verify-recaptcha`;
      const captchaResponse = await axios.post(captchaUrl, tokenPayload);

      if (captchaResponse?.data?.response?.data?.success !== true) {
        setError((prevError) => ({
          ...prevError,
          captcha: "reCAPTCHA verification failed. Please try again.",
        }));
        console.error("reCAPTCHA verification failed:", captchaResponse?.data);
        return;
      }

      console.log("reCAPTCHA verification successful:", captchaResponse?.data);

      const payload = {
        first_name: formData.firstName,
        last_name: formData.lastName,
        postal_code: formData.postalCode,
        phone_number: `+91-${formData.phoneNumber}`,
        email_id: formData.email.trim(),
        password: formData.password,
        role_id: selectedRole.roleID,
      };

      if (selectedRole.roleID === 2) {
        payload.specializations = formData.selectedSpecializations.map(
          (option) => option.value
        );
        payload.clinicIds = formData.clinic.map((option) => option.id);
      }

      const response = await axios.post(
        `${process.env.REACT_APP_NEW_API_URL}/register`,
        payload
      );
      console.log(response);
      if (response?.data?.data?.status === 200) {
        setOtpSent(true);
        setFormDisabled(true);
        const user = response.data.data.user;
        if (typeof user?.clinic_data === "string") {
          user.clinic_data = JSON.parse(user?.clinic_data);
        }
        if (typeof user?.specializations === "string") {
          user.specializations = JSON.parse(user?.specializations)
        }
        toast.success("OTP sent to your registered Email")
        console.log(response);
      } else {
        setOtpSent(false);
        setFormDisabled(false);
      }
    } catch (error) {
      setError((prevError) => ({
        ...prevError,
        signUp: error?.response?.data?.data?.message,
      }));
      console.error(
        "Error during registration:",
        error?.response?.data?.data?.message
      );
      // toast.error(message);
    }
  };

  const handleOtpSubmit = async (e) => {
    e.preventDefault();
    if (!otp) {
      setError((prev) => ({
        ...prev,
        otp: "Please enter the OTP.",
      }));
      return;
    }

    try {
      const otpResponse = await axios.post(
        `${process.env.REACT_APP_NEW_API_URL}/verify-register-otp`,
        {
          email: formData.email.trim(),
          otp,
        }
      );

      if (otpResponse?.status === 200) {
        navigate("/login");
        setFormData({
          firstName: "",
          lastName: "",
          email: "",
          postalCode: "",
          phoneNumber: "",
          password: "",
          confirmPassword: "",
          role: "",
          clinic: [],
          selectedSpecializations: []
        });
      }
    } catch (error) {
      setError((prev) => ({
        ...prev,
        otp: error?.response?.data?.message,
      }));
    }
  };

  const resendOtp = () => {
    const resendPayload = {
      email: formData.email.trim(),
      purpose: 0,
    };
    try {
      const resendOtpResponse = axios.post(
        `${process.env.REACT_APP_NEW_API_URL}/resend-otp`,
        resendPayload
      );
      console.log(resendOtpResponse);
      if (resendOtpResponse === 200) {
        setOtpSent(true);
        setIsResendDisabled(true);
        setResendOtpMessage(resendOtpResponse?.data?.result?.user);
      }
    } catch (error) {
      console.log("resend otp", error?.response?.data?.message);
    }
  };

  return (
    <div className="bg-[#F9F8F8] min-h-screen py-10 font-poppins">
      <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto lg:py-0">
        <div
          className={`w-full max-w-3xl bg-white rounded-lg shadow-lg p-6 space-y-8 ${formDisabled ? "opacity-50 pointer-events-none" : ""
            }`}
        >
          <img
            className="w-[100px] h-auto mx-auto"
            src={cognismilesLogo}
            alt="logo"
          />
          <p className="text-xl font-semibold leading-tight tracking-tight text-gray-800 md:text-2xl ">
            Create your Account
          </p>
          <form
            className="space-y-4 md:space-y-6"
            onSubmit={(e) => {
              e?.preventDefault();
              handleRegistration();
            }}
          >
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div>
                <label
                  htmlFor="firstName"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  First Name <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  id="firstName"
                  name="firstName"
                  value={formData.firstName}

                  onChange={(e) => {
                    const value = e.target.value
                      .trimStart() // removes any whitespace from the beginning
                      .replace(/[^a-zA-Z\s]/g, "");
                    setFormData((prevData) => ({
                      ...prevData,
                      [e.target.name]: value,
                    }));
                  }}
                  className="w-full p-2.5 text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 "
                  placeholder="John"
                  required
                />
              </div>
              <div>
                <label
                  htmlFor="lastName"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  Last Name <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  id="lastName"
                  name="lastName"
                  value={formData.lastName}
                  onChange={(e) => {
                    const value = e.target.value
                      .trimStart() // removes any whitespace from the beginning
                      .replace(/[^a-zA-Z\s]/g, "");
                    setFormData((prevData) => ({
                      ...prevData,
                      [e.target.name]: value,
                    }));
                  }}
                  className="w-full p-2.5 text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 "
                  placeholder="Doe"
                  required
                />
              </div>
              <div>
                <label
                  htmlFor="postalCode"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  Postal Code <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  id="postalCode"
                  name="postalCode"
                  value={formData.postalCode}
                  onChange={(e) => {
                    const value = e.target.value.replace(/[^0-9]/g, "");
                    setFormData((prevData) => ({
                      ...prevData,
                      postalCode: value,
                    }));
                  }}
                  className="w-full p-2.5 text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 "
                  placeholder="123456"
                  minLength={6}
                  maxLength={6}
                  required
                />
              </div>
              <div>
                <label
                  htmlFor="phoneNumber"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  Phone Number <span className="text-red-500">*</span>
                </label>
                <input
                  type="tel"
                  id="phoneNumber"
                  name="phoneNumber"
                  value={formData.phoneNumber}
                  onChange={(e) => {
                    const value = e.target.value.replace(/[^0-9]/g, "");
                    setFormData((prevData) => ({
                      ...prevData,
                      phoneNumber: value,
                    }));
                  }}
                  className="w-full p-2.5 text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-primary-500 focus:border-primary-500"
                  placeholder="9876543210"
                  minLength={10}
                  maxLength={10}
                  required
                />
              </div>
            </div>
            <div>
              <label
                htmlFor="email"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                Your email <span className="text-red-500">*</span>
              </label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={(e) => {
                  const value = e.target.value.trimStart(); // Trim leading spaces only
                  setFormData((prevData) => ({
                    ...prevData,
                    email: value,
                  }));
                }}
                className="w-full p-2.5 text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-primary-500 focus:border-primary-500"
                placeholder="name@company.com"
                required
              />
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div>
                <label
                  htmlFor="password"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  Password <span className="text-red-500">*</span>
                </label>
                <div className="relative">
                  <input
                    type={showPassword ? "text" : "password"}
                    id="password"
                    name="password"
                    value={formData.password}
                    onChange={(e) =>
                      handleChange("password", e.target.value.replace(" ", ""))
                    }
                    className="w-full p-2.5 text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-primary-500 focus:border-primary-500"
                    placeholder="••••••••"
                    required
                  />
                  <button
                    type="button"
                    onClick={() => setShowPassword(!showPassword)}
                    className="absolute inset-y-0 right-0 flex items-center pr-3"
                  >
                    {showPassword ? (
                      <svg
                        className="w-6 h-6 text-gray-400 cursor-pointer"
                        aria-hidden="true"
                        aria-label="Hide Password"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <path
                          stroke="currentColor"
                          strokeWidth="2"
                          d="M21 12c0 1.2-4.03 6-9 6s-9-4.8-9-6c0-1.2 4.03-6 9-6s9 4.8 9 6Z"
                        />
                        <path
                          stroke="currentColor"
                          strokeWidth="2"
                          d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                        />
                      </svg>
                    ) : (
                      <svg
                        className="w-6 h-6 text-gray-400 cursor-pointer"
                        aria-label="Show password"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M3.933 13.909A4.357 4.357 0 0 1 3 12c0-1 4-6 9-6m7.6 3.8A5.068 5.068 0 0 1 21 12c0 1-3 6-9 6-.314 0-.62-.014-.918-.04M5 19 19 5m-4 7a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                        />
                      </svg>
                    )}
                  </button>
                </div>
              </div>
              <div>
                <label
                  htmlFor="confirmPassword"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  Confirm password <span className="text-red-500">*</span>
                </label>
                <div className="relative">
                  <input
                    type={showConfirmPassword ? "text" : "password"}
                    id="confirmPassword"
                    name="confirmPassword"
                    value={formData.confirmPassword}
                    onChange={(e) =>
                      handleChange("confirmPassword", e.target.value)
                    }
                    className="w-full p-2.5 text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-primary-500 focus:border-primary-500"
                    placeholder="••••••••"
                    required
                  />
                  <button
                    type="button"
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                    className="absolute inset-y-0 right-0 flex items-center pr-3"
                  >
                    {showConfirmPassword ? (
                      <svg
                        className="w-6 h-6 text-gray-400 cursor-pointer"
                        aria-hidden="true"
                        aria-label="Hide password"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <path
                          stroke="currentColor"
                          strokeWidth="2"
                          d="M21 12c0 1.2-4.03 6-9 6s-9-4.8-9-6c0-1.2 4.03-6 9-6s9 4.8 9 6Z"
                        />
                        <path
                          stroke="currentColor"
                          strokeWidth="2"
                          d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                        />
                      </svg>
                    ) : (
                      <svg
                        className="w-6 h-6 text-gray-400 cursor-pointer"
                        aria-hidden="true"
                        aria-label="Show password"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M3.933 13.909A4.357 4.357 0 0 1 3 12c0-1 4-6 9-6m7.6 3.8A5.068 5.068 0 0 1 21 12c0 1-3 6-9 6-.314 0-.62-.014-.918-.04M5 19 19 5m-4 7a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                        />
                      </svg>
                    )}
                  </button>
                </div>
                {error.confirmPassword && (
                  <p className="mt-2 text-sm text-red-600">
                    {error.confirmPassword}
                  </p>
                )}
                <p>{resendOtpMessage}</p>
              </div>
            </div>
            <div className={`grid grid-cols-1 md:grid-cols-2 gap-6 `}>
              <div>
                <label
                  htmlFor="role"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  Select Role <span className="text-red-500">*</span>
                </label>
                <select
                  id="role"
                  name="role"
                  value={formData.role}
                  onChange={(e) => {
                    // setFormData((prevData) => ({
                    //   ...prevData,
                    //   role: e.target.value,
                    // }));
                    const newRole = e.target.value;

                    setFormData((prevData) => ({
                      ...prevData,
                      role: newRole,
                      clinic: newRole === "doctor" ? "" : prevData.clinic, // Reset clinic when switching roles
                      selectedSpecializations:
                        newRole === "doctor"
                          ? []
                          : prevData.selectedSpecializations, // Reset specializations
                    }));
                    setError((prevError) => ({ ...prevError, role: "" }));
                  }}
                  className="w-full p-3 text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-primary-500 focus:border-primary-500"
                  required
                >
                  <option value="" disabled>
                    Select a role
                  </option>
                  {roleDetails.map((role) => (
                    <option
                      key={role.roleID}
                      value={role.roleName.toLowerCase()}
                    >
                      {role.roleName}
                    </option>
                  ))}
                </select>
                {error.role && (
                  <p className="mt-2 text-sm text-red-600">{error.role}</p>
                )}
              </div>
              {formData.role === "doctor" && (
                <div>
                  <label
                    htmlFor="clinic"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    Select Clinic <span className="text-red-500">*</span>
                  </label>
                  {/* <select
                    id="clinic"
                    name="clinics"
                    value={formData.clinic}
                    onChange={(e) =>
                      setFormData((prevData) => ({
                        ...prevData,
                        clinic: e.target.value,
                      }))
                    }
                    className="w-full p-3 text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-primary-500 focus:border-primary-500"
                    required
                  >
                    <option value="" disabled>
                      Select a clinic
                    </option>
                    {clinicsList.map((clinic) => (
                      <option key={clinic.value} value={clinic.value}>
                        {clinic.label}
                      </option>
                    ))}
                  </select> */}
                  <Select
                    isMulti
                    options={clinicsList}
                    value={formData.selectedClinics}
                    onChange={(selectedOptions) => {
                      setFormData((prev) => ({
                        ...prev,
                        clinic: selectedOptions,
                      }));
                    }}
                    className="basic-multi-select text-black"
                  />
                </div>
              )}

              {formData.role === "doctor" && (
                <div>
                  <label className="block mb-2 text-sm font-medium text-gray-900">
                    Specialization <span className="text-red-500">*</span>
                  </label>
                  <Select
                    isMulti
                    options={specializations}
                    value={formData.selectedSpecializations}
                    onChange={(selectedOptions) => {
                      setFormData((prev) => ({
                        ...prev,
                        selectedSpecializations: selectedOptions,
                      }));
                    }}
                    className="basic-multi-select text-black"
                  />
                </div>
              )}
            </div>


            <div className="flex flex-col items-center space-y-4">
              <button
                type="submit"
                className="w-full sm:w-auto px-8 py-2.5 text-white bg-[#4838C8] hover:bg-primary-600 focus:ring-4 focus:outline-none focus:ring-[#4838C8]-300 font-medium rounded-lg text-sm text-center"
              >
                Create an account
              </button>
              {error.captcha && (
                <p className="mt-2 text-sm text-red-600">{error.captcha}</p>
              )}
              {error.signUp && (
                <p className="mt-2 text-sm text-red-600">{error.signUp}</p>
              )}

              <p className="text-sm text-gray-700">
                Already have an account?{" "}
                <a
                  href="/login"
                  className="font-medium text-[#4838C8] hover:underline"
                >
                  Login here
                </a>
              </p>
            </div>
          </form>
        </div>
        {otpSent && (
          <div
            ref={otpSectionRef}
            className="bg-white rounded-lg shadow-md p-6 sm:p-8 my-8"
          >
            <h2 className="text-lg font-medium text-gray-800 text-center mb-4">
              OTP Verification
            </h2>
            <p className="text-sm text-gray-600 text-center mb-4">
              Enter the OTP sent to{" "}
              <strong className="font-bold text-black">{maskedEmail}</strong>
            </p>
            <form onSubmit={handleOtpSubmit} className="space-y-4">
              <div>
                <label
                  htmlFor="otp"
                  className="block text-sm lg:text-lg font-medium text-gray-700 mb-1"
                >
                  OTP
                </label>
                <input
                  type="text"
                  id="otp"
                  className="w-full px-3 py-2 border border-[#F2F4F4] rounded-md shadow-sm focus:ring-black focus:border-black"
                  value={otp}
                  onChange={(e) => {
                    setOtp(e.target.value);
                    setError((prev) => ({ ...prev, otp: "" })); // Clear OTP error message
                  }}
                  placeholder="Enter OTP"
                />
              </div>
              {error?.otp && (
                <p className="text-red-500 text-sm">{error?.otp}</p>
              )}
              <button
                type="submit"
                className="w-full px-8 py-2.5 text-white bg-[#4838C8] font-medium rounded-lg text-sm text-center"
              >
                Verify OTP
              </button>
            </form>
            <div className="mt-4 text-center">
              <span className="text-gray-500">Didn't receive OTP? </span>
              <button
                type="button"
                className={`cursor-pointer ${isResendDisabled
                    ? "text-gray-500 opacity-50 cursor-not-allowed"
                    : "text-blue-500"
                  }`}
                onClick={resendOtp}
                disabled={isResendDisabled}
              >
                {isResendDisabled ? `Resend in ${resendTimer}s` : "Resend OTP"}
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Signup;
