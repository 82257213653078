import React, { useState, useEffect, useContext, useCallback } from "react";
import {
  flexRender,
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
  getFilteredRowModel,
} from "@tanstack/react-table";

import { useNavigate } from "react-router-dom";
import { UserContext } from "../Context/UserContext";
import axios from "axios";
//import { toast } from "react-toastify";
import EditUserPopup from "../OpenaiComponents/EditUserPopup";

import Header from "./Header";
import Sidebar from "./Sidebar";
import { format } from "date-fns";
import { toast } from "react-toastify";

const UsersListTable = () => {
   const [loading, setLoading] = useState(false);
   const [usersList, setUsersList] = useState([]);
  const [searchWord, setSearchWord] = useState("");
  const [columnSorting, setColumnSorting] = useState([]);
  const [isMenuOpen, setIsMenuOpen] = useState(true);
  const [editUser, setEditUser] = useState({ show: false, userData: {} });
  //const [newUser, setNewUser] = useState({ show: false });
  //const [isModalOpen, setModalOpen] = useState(false);
  // const [activeUsers, setActiveUsers] = useState({});
  // const [selectedActiveId, setSelectedActiveId] = useState(null);
  const [userToChangeActiveStatus, setUserToChangeActiveStatus] =
    useState(null);
  const [selectedRoleIds, setSelectedRoleIds] = useState([1, 2]);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const { users } = useContext(UserContext); 
  const token = users?.token;
  const navigate = useNavigate();
  

  useEffect(() => {
    if (!token) {
      navigate("/login");
      return;
    }
  }, [token, navigate]);


  const roles = {
    1: "Admin",
    2: "Doctor",
    3: "Patient",
  };

  const roleDetails = [
    {
      role_id: 1,
      role_name: "Admin",
    },
    {
      role_id: 2,
      role_name: "Doctor",
    },
    {
      role_id: 3,
      role_name: "Patient",
    },
  ];

  const handleRoleChange = (roleId) => {
    setSelectedRoleIds((prevIds) =>
      prevIds.includes(roleId)
        ? prevIds.filter((id) => id !== roleId)
        : [...prevIds, roleId]
    );
  };


  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

  const fetchUserList = useCallback(async () => {
    if (!token) return
    setLoading(true);
    try {
      const url = `${process.env.REACT_APP_NEW_API_URL}/get-all-users`;

      const response = await axios.get(url, {
        headers: { Authorization: `Bearer ${token}` },
      });

      console.log("get users details",response.data.data.details)

      if (response.status === 200) {
        const users = response.data.data.details; 
        const usersData =  users.map((user) => {
          let parsedClinicData = [];
          let parsedSpecializationData = []
          if (user.clinic_data && typeof user.clinic_data === "string") {
            // console.log(user?.id ,user.clinic_data)
            try {
              parsedClinicData = JSON.parse(user.clinic_data);
            } catch (error) {
              console.error(
                `Error parsing clinic_data for user ${user.id}:`,
                error
              );
            }
          }
          if (user.specializations && typeof user.specializations === "string") {
            try {
              parsedSpecializationData = JSON.parse(user.specializations);
            } catch (error) {
              console.error(
                `Error parsing clinic_data for user ${user.id}:`,
                error
              );
            }
          }
          return {
            ...user,
            clinic_data: parsedClinicData, // Replace string with parsed array
            specializations:parsedSpecializationData
          };
        });

        // // if (typeof doctorData?.clinic_data === "string") {
        // //   doctorData.clinic_data = JSON.parse(doctorData.clinic_data);
        // // }
         console.log("parsed users details",usersData);


        const filteredUsers = usersData?.filter((user) =>
          selectedRoleIds.includes(user.role_id)
        );

        // setUsersList(newData);
        setUsersList(filteredUsers);
      }
    } catch (error) {
      if (error.response?.status === 400) {
        navigate("/login");
        toast.error("Session expired,Please login again")
      }
      console.error(
        `Error fetching user list table:`,
        error?.response?.data?.error
      );
    } finally {
      setLoading(false);
    }
  }, [token, navigate, selectedRoleIds]);

  useEffect(() => {
    fetchUserList();
  }, [fetchUserList]);

  const handleActiveStatus = async (modifiedUser) => {
    console.log("Active Status: ", modifiedUser, ":", !modifiedUser.is_active);
    try {
      const payload = {
        is_active: Number(!modifiedUser.is_active),
        idOrIdentity: String(modifiedUser?.id),
      };

      const url = `${process.env.REACT_APP_NEW_API_URL}/active-or-deactive-user?idOrIdentity=${modifiedUser?.id}`;
      const response = await axios.put(url, payload, {
        headers: { Authorization: `Bearer ${token}` },
      });

      if (response?.status === 200) {
        setUsersList((prevList) =>
          prevList.map((users) => {
            if (users.id === modifiedUser.id) {
              return { ...users, is_active: Number(!users.is_active) };
            }
            return users;
          })
        );
        setUserToChangeActiveStatus(null);
        console.log("Status changed successfully!");
      }

      console.log(response);
    } catch (error) {
      console.log("error in status change", error?.message);
      if (error?.status === 400) {
        toast.error("Session expired,please login again") 
        navigate("/login")
      }
    }
  };

  const userTableColumns = [
    {
      header: "Patient Id",
      accessorKey: "identity",
    },
    {
      header: "Name",
      accessorFn: (row) => `${row.first_name} ${row.last_name}`,
    },
    {
      header: "Email Id",
      accessorKey: "email_id",
    },
    {
      header: "Contact Number",
      accessorKey: "phone_number",
    },
    {
      header: "Postal Code",
      accessorKey: "postal_code",
    },
    {
      header: "Created At",
      accessorFn: (row) => format(new Date(row.created_at), "dd-MM-yyyy HH:mm:ss ") + "IST",
    },
    // {
    //   header: "Created by",
    //   accessorKey: "",
    // },
    {
      header: "Role",
      accessorFn: (row) => roles[row.role_id],
    },
    {
      header: "Action",
      accessorKey: "action",
      cell: (row) => (
        <div>
          <button
            className="mx-auto w-full flex justify-center"
            onClick={() =>
              setEditUser({ show: true, userData: row.row.original })
            }
          >
            <svg
              className="w-6 h-6 text-center text-[#4838C8] "
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke="currentColor"
                strokeLinecap="square"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M7 19H5a1 1 0 0 1-1-1v-1a3 3 0 0 1 3-3h1m4-6a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm7.441 1.559a1.907 1.907 0 0 1 0 2.698l-6.069 6.069L10 19l.674-3.372 6.07-6.07a1.907 1.907 0 0 1 2.697 0Z"
              />
            </svg>
          </button>
        </div>
      ),
    },
    {
      header: "Active",
      accessorKey: "active",
      cell: (row) => {
        return row.row.original?.is_verified ? (
          <button
            className="mx-auto w-full flex justify-center"
            onClick={() => setUserToChangeActiveStatus(row.row.original)}
          >
            <div
              className={`border  border-[#4838c8] ${
                row.row.original.is_active ? "bg-[#4838c8]" : "bg-[#E5F7FC]"
              }  w-12  h-6 rounded-full`}
            >
              <div
                className={`transition-all duration-700 ${
                  row.row.original.is_active ? " translate-x-full" : ""
                }  h-full w-6 rounded-full bg-white`}
              ></div>
            </div>
          </button>
        ) : (
          <p className="text-sm text-red-400 text-center">Not verified</p>
        );
      },
    },
  ];

  const tableData = useReactTable({
    data: usersList,
    columns: userTableColumns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),

    state: {
      globalFilter: searchWord,
      columnSorting,
    },
    onGlobalFilterChange: setSearchWord,
    onColumnSortingChange: setColumnSorting,
  });


  return (
    <div>
      {/* Header */}
      <Header toggleMenu={toggleMenu} />

      {/* Main content */}
      <div className="flex min-h-[calc(100vh-90px)] overflow-x-hidden">
        {/* Sidebar */}
        <Sidebar isMenuOpen={isMenuOpen} />
        {/* Table content */}
        <div className="flex-grow bg-[#F9F8F8] px-4 py-2  font-poppins min-h-[calc(100vh-90px)] overflow-x-hidden">
          <div className="w-full flex flex-col items-start justify-start  gap-5 min-h-[calc(100vh-12vh)] overflow-x-auto">
            {loading ? (
              <div className="flex justify-center w-full mt-20">
                {/* Loading Spinner */}
                <svg
                  className="inline w-7 h-7 text-[#4838C8] animate-spin fill-white"
                  viewBox="0 0 100 101"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill="currentColor"
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  />
                  <path
                    fill="currentFill"
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  />
                </svg>
              </div>
            ) : usersList.length > 0 ? (
              <>
                <div className="w-full flex flex-col sm:flex-row justify-between items-center sm:gap-4 gap-4 px-4 sm:px-0">
                  <p className="text-lg font-semibold md:text-xl text-center sm:text-left">
                    List of Users
                  </p>

                  <div className="flex flex-col sm:flex-row items-center gap-2 md:gap-4  w-full sm:w-auto">
                    {/* Add New Button */}
                    {/* <button
                      className="bg-transparent text-[#4838c8] rounded-lg py-3 px-4 border border-[#4838C8] w-full sm:w-auto"
                      onClick={() => setNewUser({ show: true })}
                    >
                      Add New
                    </button> */}

                    {/* Search Input */}
                    <div className="border rounded-full py-[10px] px-3 text-xs border-gray-300 flex gap-2 items-center w-full sm:w-auto order-2 md:order-1">
                      <button
                        aria-label="Search"
                        title="Search"
                        className="focus:outline-none focus:ring-2 focus:ring-black rounded-sm p-1"
                      >
                        <svg
                          className="w-4 h-4 text-gray-500"
                          fill="none"
                          viewBox="0 0 20 20"
                          aria-hidden="true"
                        >
                          <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                          />
                        </svg>
                      </button>
                      <input
                        type="text"
                        placeholder="Search"
                        className="w-[85%] outline-none border-none bg-transparent"
                        value={searchWord}
                        onChange={(e) => setSearchWord(e.target.value)}
                        aria-label="Search input"
                      />
                    </div>

                    {/* Filter Dropdown */}
                    {/* <div className="relative w-full sm:w-auto md:order-2 order-3">
                      <button
                        className="border p-2  sm:w-auto w-full  bg-slate-200 text-left   rounded-md focus:outline-none flex justify-between items-center"
                        onClick={() => setDropdownOpen(!dropdownOpen)}
                      >
                        Filter by Role
                        <span className="ml-1">
                          {dropdownOpen ? (
                            <svg
                              className="w-6 h-6 text-gray-800"
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                            >
                              <path
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="m16 14-4-4-4 4"
                              />
                            </svg>
                          ) : (
                            <svg
                              className="w-6 h-6 text-gray-800"
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                            >
                              <path
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="m8 10 4 4 4-4"
                              />
                            </svg>
                          )}
                        </span>
                      </button>

                      {dropdownOpen && (
                        <div className="absolute left-0 sm:right-0 z-10 mt-2 bg-white border shadow-lg rounded-md p-3 w-full sm:w-40">
                          {roleDetails.map((role) => (
                            <label
                              key={role.id}
                              className="block mb-2 cursor-pointer"
                            >
                              <input
                                type="checkbox"
                                className="mr-2"
                                checked={selectedRoleIds.includes(role.role_id)}
                                onChange={() => handleRoleChange(role.role_id)}
                              />
                              {role.role_name}
                            </label>
                          ))}
                        </div>
                      )}
                    </div> */}
                    <div className="relative w-full sm:w-auto md:order-2 order-3">
                      <button
                        className="border px-4 py-2 sm:w-auto w-full bg-white text-gray-700 font-medium text-left rounded-md shadow-md flex justify-between items-center hover:bg-gray-100 transition-all"
                        onClick={() => setDropdownOpen(!dropdownOpen)}
                      >
                        Filter by Role
                        <span className="ml-2">
                          {dropdownOpen ? (
                            <svg
                              className="w-5 h-5 text-gray-700"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                            >
                              <path
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="m16 14-4-4-4 4"
                              />
                            </svg>
                          ) : (
                            <svg
                              className="w-5 h-5 text-gray-700"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                            >
                              <path
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="m8 10 4 4 4-4"
                              />
                            </svg>
                          )}
                        </span>
                      </button>

                      {dropdownOpen && (
                        <div className="absolute left-0 sm:right-0 z-20 mt-2 bg-white border border-gray-300 shadow-lg rounded-lg p-1 w-full ">
                          {roleDetails.map((role) => (
                            <label
                              key={role.id}
                              className="flex items-center px-3 py-2 rounded-md cursor-pointer hover:bg-gray-100 transition-all"
                            >
                              <input
                                type="checkbox"
                                className="mr-2 accent-blue-500"
                                checked={selectedRoleIds.includes(role.role_id)}
                                onChange={() => handleRoleChange(role.role_id)}
                              />
                              <span className="text-gray-700">
                                {role.role_name}
                              </span>
                            </label>
                          ))}
                        </div>
                      )}
                    </div>

                    <div className="order-1 md:order-2">
                      <p className="text-lg md:text-base text-gray-600">
                        Total Records:{" "}
                        <span className="font-bold text-black">
                          {usersList.length}
                        </span>
                      </p>
                    </div>
                  </div>
                </div>

                {/* <div className="flex justify-center items-center text-center mt-4">
                  <p>
                    {" "}
                    Total Number Of Record:{" "}
                    <span className="font-bold text-black">{totalRecords}</span>
                  </p>
                </div> */}

                {/* Table */}
                <div className="w-full overflow-x-auto">
                  <table className="w-full ">
                    <thead className="min-w-full text-sm border-[#c0c0c0]  border cursor-pointer">
                      {tableData.getHeaderGroups().map((headerGroup) => (
                        <tr
                          key={headerGroup.id}
                          className="rounded-t-md bg-[#E9F0FF]  text-black text-sm divide-x divide-gray-400"
                        >
                          {headerGroup.headers.map((header) => (
                            <th
                              key={header.id}
                              className={`py-2 px-2 text-start font-medium`}
                              onClick={header.column.getToggleSortingHandler()}
                              aria-label={`Sort by ${header.column.columnDef.header}`}
                            >
                              {flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )}
                              <span className="text-white">
                                {{
                                  asc: " ▲",
                                  desc: " ▼",
                                }[header.column.getIsSorted()] ?? ""}
                              </span>
                            </th>
                          ))}
                        </tr>
                      ))}
                    </thead>
                    <tbody className="bg-white divide-y divide-[#C0C0C0] border-b border-[#C0C0C0]">
                      {tableData.getRowModel().rows.map((row) => (
                        <tr
                          key={row.id}
                          className="divide-x divide-[#C0C0C0] border-x border-[#C0C0C0] rounded-b-md text-start text-sm cursor-pointer"
                        >
                          {row.getVisibleCells().map((cell) => (
                            <td key={cell.id} className="py-2 px-2">
                              {flexRender(
                                cell.column.columnDef.cell,
                                cell.getContext()
                              )}
                            </td>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </>
            ) : (
              <div className="w-full flex justify-center items-center min-h-[300px]">
                <p>No Users Found.</p>
              </div>
            )}
            {userToChangeActiveStatus && (
              <dialog
                open
                className="p-3 inset-0 fixed z-10 w-full min-h-screen bg-black/30"
              >
                <div className="w-full min-h-screen p-4 flex justify-center items-center">
                  <div className="bg-white flex flex-col p-8 w-full max-w-[400px] rounded-md shadow-md relative">
                    <p className="text-base text-center">
                      Do You want to{" "}
                      {userToChangeActiveStatus ? "Activate" : "Dectivate"}
                      <br />
                      {userToChangeActiveStatus.first_name +
                        " " +
                        userToChangeActiveStatus.last_name}
                    </p>
                    <div className="self-center space-x-4 mt-12">
                      <button
                        className="px-5 py-2 rounded-md text-sm bg-red-500 hover:bg-red-700 text-white"
                        onClick={() =>
                          handleActiveStatus(userToChangeActiveStatus)
                        }
                      >
                        {" "}
                        Yes
                      </button>
                      <button
                        className="px-5 py-2 rounded-md text-sm border border-red-500"
                        onClick={() => setUserToChangeActiveStatus(null)}
                      >
                        No
                      </button>
                    </div>
                  </div>
                </div>
              </dialog>
            )}

            {/* Popups */}
            {editUser.show && (
              <EditUserPopup editUser={editUser} setEditUser={setEditUser} fetchUsers = {fetchUserList}/>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UsersListTable;
