import React, { useContext, useEffect, useState } from "react";
import useEnquiresList from "../hooks/useEnquiresList";
import { UserContext } from "../Context/UserContext";
import { useNavigate } from "react-router-dom";
import {
  flexRender,
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
  getFilteredRowModel,
} from "@tanstack/react-table";
import Header from "./Header";
import Sidebar from "./Sidebar";
import PatientDetails from "../OpenaiComponents/PatientDetails";
import { format } from "date-fns";

const Patient = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(true);
  const [searchWord, setSearchWord] = useState("");
  const [columnSorting, setColumnSorting] = useState([]);

  const [selectedId, setSelectedId] = useState(null);
  const { enquiresList, loading } = useEnquiresList();

  const {
    users,
    isSidebarOpen,
    setIsSidebarOpen,
    setEnquiresIdentity,
    setEnquires,
  } = useContext(UserContext);

  const navigate = useNavigate();

  const token = users?.token;

  useEffect(() => {
    if (!token) {
      navigate("/login");
    }
  }, [token, navigate]);

  const enquiresFormTableColumns = [
    {
      header: "Patient Name",
      accessorKey: "name",
      cell: ({ getValue }) => (
        <span className="text-blue-500 underline underline-offset-4 cursor-pointer">
          {getValue()}
        </span>
      ),
    },

    {
      header: "Patient ID",
      accessorKey: "identity",
    },

    {
      header: "Email ID",
      accessorKey: "email_id",
    },
    {
      header: "Contact Number",
      accessorKey: "phone",
    },
    {
      header: "Created At",
      accessorFn: (row) => format(new Date(row?.child_object?.[0]?.created_at),"dd-MM-yyyy HH:mm:ss") +" IST",

    },
  ];

  const tableData = useReactTable({
    data: enquiresList,
    columns: enquiresFormTableColumns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    state: {
      globalFilter: searchWord,
      columnSorting,
    },
    onGlobalFilterChange: setSearchWord,
    onColumnSortingChange: setColumnSorting,
  });

  useEffect(() => {
    setIsSidebarOpen(false);
  }, []);

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

  const openSidebar = (enquires) => {
    setEnquires(enquires);
    setEnquiresIdentity(enquires?.identity);
    setSelectedId(enquires?.id);
    setIsSidebarOpen(true);
  };

  const closeSidebar = () => {
    setIsSidebarOpen(false);
    setEnquires([]);
    setSelectedId(null);
  };

  useEffect(() => {
    if (isSidebarOpen) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }

    return () => {
      document.body.classList.remove("overflow-hidden");
    };
  }, [isSidebarOpen]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [isSidebarOpen]);

  return (
    <div>
      <Header toggleMenu={toggleMenu} />
      <div className="flex min-h-[calc(100vh-90px)] overflow-x-hidden">
        <Sidebar isMenuOpen={isMenuOpen} />
        <div className="flex-grow bg-[#F9F8F8] px-4 py-2  font-poppins min-h-[calc(100vh-90px)] overflow-x-hidden ">
          <div className="w-full flex flex-col items-start justify-start gap-5   overflow-x-auto min-h-[100vh] ">
            {enquiresList.length > 0 && (
              <div className="w-full flex flex-col md:flex-row justify-between items-center gap-2 px-4 md:px-0 ">
                {/* Heading */}
                <p className="text-lg md:text-xl font-semibold mb-2 md:mb-0">
                  List of Patients
                </p>

                {/* Search & Count Container */}
                <div className="flex flex-col md:flex-row items-center gap-2 md:gap-4 w-full md:w-auto">
                  {/* Search Bar */}
                  <div className="border rounded-full py-2 px-3 text-sm border-gray-300 flex gap-2 items-center w-full md:w-auto order-2 md:order-1">
                    <button
                      aria-label="Search"
                      title="Search"
                      className="focus:outline-none focus:ring-2 focus:ring-black rounded-full p-2"
                    >
                      <svg
                        className="w-4 h-4 text-gray-500"
                        fill="none"
                        viewBox="0 0 20 20"
                        aria-hidden="true"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                        />
                      </svg>
                    </button>
                    <input
                      type="text"
                      placeholder="Search"
                      className="w-full outline-none border-none bg-transparent text-sm"
                      value={searchWord}
                      onChange={(e) => setSearchWord(e.target.value)}
                      aria-label="Search input"
                    />
                  </div>

                  {/* Total Count */}
                  <div className="order-1 md:order-2">
                    <p className="text-lg md:text-base text-gray-600">
                      Total Records:{" "}
                      <span className="font-bold text-black">
                        {enquiresList.length}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            )}
            <div className="w-full overflow-x-auto">
              {loading ? (
                <div className="flex justify-center overflow-hidden w-full mt-16">
                  <svg
                    className="inline w-7 h-7 text-[#4838C8] animate-spin fill-white"
                    viewBox="0 0 100 101"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill="currentColor"
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    />
                    <path
                      fill="currentFill"
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    />
                  </svg>
                </div>
              ) : enquiresList.length > 0 ? (
                <>
                  <table className="w-full">
                    <thead className="min-w-full text-sm border-[#c0c0c0] border rounded-t-md ">
                      {tableData.getHeaderGroups().map((headerGroup) => (
                        <tr
                          key={headerGroup.id}
                          className=" bg-[#E9F0FF] text-sm divide-x divide-[#c0c0c0]"
                        >
                          {headerGroup.headers.map((header) => (
                            <th
                              key={header.id}
                              className={`py-2 px-2  text-start font-medium ${
                                users?.role_id !== 1 && ""
                              } `}
                            >
                              {flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )}
                            </th>
                          ))}
                        </tr>
                      ))}
                    </thead>
                    <tbody className="bg-white divide-y divide-[#c0c0c0] border-b border-[#c0c0c0]">
                      {tableData.getRowModel().rows.map((row, index) => (
                        <tr
                          key={row.id}
                          className={`divide-x divide-[#c0c0c0] border-x border-[#c0c0c0]  rounded-b-md text-start text-sm  
                            ${
                              selectedId === row.original.id
                                ? "bg-[#DCEFF4]" // Apply background color for the active row
                                : "hover:bg-[#dae1f072]" // Apply hover background color for other rows
                            }
                          `}
                          onClick={() => openSidebar(row?.original)}
                        >
                          {row.getVisibleCells().map((cell) => (
                            <td key={cell.id} className="py-2 px-2 ">
                              {flexRender(
                                cell.column.columnDef.cell,
                                cell.getContext()
                              )}
                            </td>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
        {isSidebarOpen && <PatientDetails closeSidebar={closeSidebar} />}
      </div>
    </div>
  );
};

export default Patient;
