import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { UserContext } from "../Context/UserContext";
import { format } from "date-fns";
import { toast } from "react-toastify";

const NotesPopup = (props) => {
  const { file, closeNotes } = props;
  console.log(file);
  const { file_name, file_type } = file;
  const [notes, setNotes] = useState("");
  const [notesList, setNotesList] = useState([]);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const navigate = useNavigate();
  const { users } = useContext(UserContext);
  const token = users?.token;
  const name = users?.first_name;
  const roles = { 1: "Admin", 2: "Doctor", 3: "Patient" };
  const role = roles[users?.role_id];

  useEffect(() => {
    fetchAllNotes(file?.file_id);
  }, [file?.file_id]);

  const fetchAllNotes = async (fileId) => {
    console.log("fileId", fileId);
    try {
      const url = `${process.env.REACT_APP_NEW_API_URL}/get-notes?file_id=${fileId}`;
      const response = await axios.get(url, {
        headers: { Authorization: `Bearer ${token}` },
      });
      console.log(response);
      if (response?.status === 200) {
        setNotesList(response?.data?.data);
      }
    } catch (error) {
      console.log("get all notes", error);
      if (error?.response?.status === 400) {
        navigate("/login");
      }
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!notes.trim()) return; // Prevent empty prompts

    setIsSubmitted(true);
    try {
      const payload = {
        file_id: file?.file_id,
        notes: notes,
        created_by: `${name}-${role}`,
      };

      const response = await axios.post(
        `${process.env.REACT_APP_NEW_API_URL}/add-notes`,
        payload,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      console.log(response);
      if (response?.status === 200) {
        setNotesList(response?.data?.data);
        setNotes("");
      }
    } catch (error) {
      console.log("adding new notes", error);
      if (error?.response?.status === 400) {
        toast.error("session expired, please login again")
        navigate("/login");
      }
    } finally {
      setIsSubmitted(false);
    }
  };
  console.log(notesList);
  return (
    <div className=" p-3 inset-0 fixed z-30 w-full min-h-screen flex justify-center items-center">
      <div className="bg-white flex flex-col p-4 w-full max-w-[1000px] h-[70vh] rounded-md shadow-md relative  overflow-hidden  my-5 font-poppins">
        <div className="w-full flex justify-between items-center">
          <p className="text-base ">
            <span className="font-bold">Notes for </span>
            <span className="font-semibold text-black underline">
              {file_name}
            </span>
            <span> - {file_type}</span>
          </p>
          <button onClick={() => closeNotes()}>
            <svg
              className="w-6 h-6 text-gray-800 "
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="m15 9-6 6m0-6 6 6m6-3a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
              />
            </svg>
          </button>
        </div>
        <div className="w-full overflow-y-auto mt-2  flex-grow p-2 ">
          {notesList.length > 0 ? (
            notesList.map((note) => (
              <div key={note?.id} className="my-2 bg-slate-100 rounded-lg p-2">
                <p className="text-sm text-gray-800 whitespace-pre-line mb-2">
                  {note.notes}
                </p>
                <p className="text-xs text-gray-500">
                  Added by: {note.created_by} on{" "}
                  {format(new Date(note.created_at), "dd-MM-yyyy HH:mm:ss") +
                    " IST"}
                </p>
              </div>
            ))
          ) : (
            <div className="flex items-center justify-center h-40">
              <p className="text-gray-500 text-center">
                No notes available, Please add Notes{" "}
              </p>
            </div>
          )}
        </div>
        <div className="mt-auto pt-3 pr-4">
          <form onSubmit={handleSubmit} className="w-full">
            <div className="relative w-full ">
              <textarea
                placeholder="Type your notes here..."
                className="w-full px-4 py-2 pr-8 border border-slate-200 rounded-lg focus:outline-none focus:ring-1 focus:ring-slate-300 resize-none h-20 bg-slate-100 "
                onChange={(e) => setNotes(e.target.value)}
                value={notes}
                onKeyDown={(e) => {
                  if (e.key === "Enter" && !e.shiftKey) {
                    e.preventDefault();
                    handleSubmit(e);
                  }
                }}
              />
              <button
                type="submit"
                className={`absolute right-6 bottom-3 text-blue-500 hover:text-blue-700 ${
                  isSubmitted ? "cursor-not-allowed" : ""
                }`}
                disabled={isSubmitted}
              >
                ➤
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
export default NotesPopup;
