import React, { useState } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import cognismilesLogo from "../assets/cognismiles-logo.png";
import { toast } from "react-toastify";

const SetPassword = () => {
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState("");
  const [showConfirmPassword, setShowConfirmPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const { token } = useParams();

  console.log(token);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setError("*Passwords do not match!");
      return;
    }

    try {
      const payload = {
        token: token,
        newPassword: password,
      };
      const response = await axios.post(
        `${process.env.REACT_APP_NEW_API_URL}/set-password`,
        payload
      );

      console.log(response);
      if (response?.status === 200) {
        setError("");
        toast.success("New Password has been set successfully.");

        navigate("/login");
      }
    } catch (error) {
      console.log("error", error);
      setError(error?.response?.data?.message);
    }
  };
  return (
    <div className="bg-[#F9F8F8] font-poppins min-h-screen  flex items-center justify-center px-6 py-8 lg:py-0">
      <div className="w-full p-6  bg-white rounded-lg  shadow-md md:mt-0 sm:max-w-md  sm:p-8 ">
        <img
          className="w-[100px] h-auto mx-auto"
          src={cognismilesLogo}
          alt="logo"
        />
        <p className="mt-3 text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl ">
          Set password{" "}
        </p>
        <form class="mt-4 space-y-4 lg:mt-5 md:space-y-5">
          <div>
            <label
              htmlFor="password"
              className="block mb-2 font-medium text-black "
            >
              New Password
            </label>
            <div className="relative">
              <input
                type={showPassword ? "text" : "password"}
                name="password"
                value={password}
                onChange={(e) => setPassword(e.target.value.replace(" ", ""))}
                placeholder="••••••••"
                className="outline-none shadow-sm border border-gray-300 text-gray-900 sm:text-sm rounded-lg  block w-full p-2.5 "
                required
              />
              {showPassword ? (
                <svg
                  onClick={() => setShowPassword(!showPassword)}
                  className="w-6 h-6 text-gray-400 absolute top-[20%] right-2 cursor-pointer"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke="currentColor"
                    strokeWidth="2"
                    d="M21 12c0 1.2-4.03 6-9 6s-9-4.8-9-6c0-1.2 4.03-6 9-6s9 4.8 9 6Z"
                  />
                  <path
                    stroke="currentColor"
                    strokeWidth="2"
                    d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                  />
                </svg>
              ) : (
                <svg
                  onClick={() => setShowPassword(!showPassword)}
                  className="w-6 h-6 text-gray-400 absolute top-[20%] right-2 cursor-pointer"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M3.933 13.909A4.357 4.357 0 0 1 3 12c0-1 4-6 9-6m7.6 3.8A5.068 5.068 0 0 1 21 12c0 1-3 6-9 6-.314 0-.62-.014-.918-.04M5 19 19 5m-4 7a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                  />
                </svg>
              )}
            </div>
          </div>
          <div>
            <label
              htmlFor="confirmPassword"
              className="block mb-2 font-medium text-black "
            >
              Confirm password
            </label>
            <div className="relative">
              <input
                type={showConfirmPassword ? "text" : "password"}
                name="confirmPassword"
                value={confirmPassword}
                onChange={(e) =>
                  setConfirmPassword(e.target.value.replace(" ", ""))
                }
                placeholder="••••••••"
                className="outline-none shadow-sm border border-gray-300 text-gray-900 sm:text-sm rounded-lg  block w-full p-2.5 "
                required
              />
              {showConfirmPassword ? (
                <svg
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  className="w-6 h-6 text-gray-400 absolute top-[20%] right-2 cursor-pointer"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke="currentColor"
                    strokeWidth="2"
                    d="M21 12c0 1.2-4.03 6-9 6s-9-4.8-9-6c0-1.2 4.03-6 9-6s9 4.8 9 6Z"
                  />
                  <path
                    stroke="currentColor"
                    strokeWidth="2"
                    d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                  />
                </svg>
              ) : (
                <svg
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  className="w-6 h-6 text-gray-400 absolute top-[20%] right-2 cursor-pointer"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M3.933 13.909A4.357 4.357 0 0 1 3 12c0-1 4-6 9-6m7.6 3.8A5.068 5.068 0 0 1 21 12c0 1-3 6-9 6-.314 0-.62-.014-.918-.04M5 19 19 5m-4 7a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                  />
                </svg>
              )}
            </div>
          </div>
          {error && <p className="text-red-500 text-sm font-medium">{error?.message}</p>}
          <button
            onClick={handleSubmit}
            className="w-full text-white bg-[#4838c8] focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center "
          >
            Update
          </button>
        </form>
      </div>
    </div>
  );
};

export default SetPassword;
