import React, { useContext, useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { UserContext } from "../Context/UserContext";
import axios from "axios";
import Header from "./Header";
import Sidebar from "./Sidebar";
import PatientDetails from "../OpenaiComponents/PatientDetails";
import AddNewPromptSummaryPopup from "../OpenaiComponents/AddNewPromptSummaryPopup";
import NotesPopup from "../OpenaiComponents/Notes";
import { format } from "date-fns";

// const NotesPopup = (props) => {
//   const { file, closeNotes } = props;
//   const { file_name, file_type } = file;
//   const [notes, setNotes] = useState("");
//   const [notesList, setNotesList] = useState([]);
//   const [isSubmitted, setIsSubmitted] = useState(false);

//   const navigate = useNavigate();
//   const { users } = useContext(UserContext);
//   const token = users?.token;
//   const name = users?.first_name;
//   const roles = { 1: "Admin", 2: "Doctor", 3: "Patient" };
//   const role = roles[users?.role_id];

//   useEffect(() => {
//     fetchAllNotes(file?.file_id);
//   }, [file?.file_id]);

//   const fetchAllNotes = async (fileId) => {
//     console.log("fileId", fileId);
//     try {
//       const url = `${process.env.REACT_APP_NEW_API_URL}/get-notes?file_id=${fileId}`;
//       const response = await axios.get(url, {
//         headers: { Authorization: `Bearer ${token}` },
//       });
//       console.log(response);
//       if (response?.status === 200) {
//         setNotesList(response?.data?.data);
//       }
//     } catch (error) {
//       console.log("get all notes", error);
//       if (error?.response?.status === 400) {
//         navigate("/login");
//       }
//     }
//   };
//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     if (!notes.trim()) return; // Prevent empty prompts

//     setIsSubmitted(true);
//     try {
//       const payload = {
//         file_id: file?.file_id,
//         notes: notes,
//         created_by: `${name}-${role}`,
//       };

//       const response = await axios.post(
//         `${process.env.REACT_APP_NEW_API_URL}/add-notes`,
//         payload,
//         {
//           headers: { Authorization: `Bearer ${token}` },
//         }
//       );
//       console.log(response);
//       if (response?.status === 200) {
//         setNotesList(response?.data?.data);
//         setNotes("");
//       }
//     } catch (error) {
//       console.log("adding new notes", error);
//       if (error?.response?.status === 400) {
//         navigate("/login");
//       }
//     } finally {
//       setIsSubmitted(false);
//     }
//   };
//   console.log(notesList);
//   return (
//     <div className=" inset-0 fixed z-10 w-full min-h-screen flex justify-center items-center">
//       <div className="bg-white flex flex-col p-4 w-full max-w-[600px] h-[60vh] rounded-md shadow-md relative  overflow-hidden  my-5 font-poppins">
//         <div className="w-full flex justify-between items-center">
//           <p className="text-base ">
//             <span>Notes for </span>
//             <span className="font-semibold text-black underline">
//               {file_name}
//             </span>
//             <span> - {file_type}</span>
//           </p>
//           <button onClick={() => closeNotes()}>
//             <svg
//               className="w-6 h-6 text-gray-800 "
//               aria-hidden="true"
//               xmlns="http://www.w3.org/2000/svg"
//               width="24"
//               height="24"
//               fill="none"
//               viewBox="0 0 24 24"
//             >
//               <path
//                 stroke="currentColor"
//                 strokeLinecap="round"
//                 strokeLinejoin="round"
//                 strokeWidth="2"
//                 d="m15 9-6 6m0-6 6 6m6-3a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
//               />
//             </svg>
//           </button>
//         </div>
//         <div className="w-full overflow-y-auto mt-2  flex-grow p-2 ">
//           {notesList.length > 0 ? (
//             notesList.map((note) => (
//               <div key={note?.id} className="my-2 bg-slate-100 rounded-lg p-2">
//                 <p className="text-sm text-gray-800 whitespace-pre-line mb-2">
//                   {note.notes}
//                 </p>
//                 <p className="text-xs text-gray-500">
//                   Added by: {note.created_by} on{" "}
//                   {new Date(note.created_at).toLocaleString()}
//                 </p>
//               </div>
//             ))
//           ) : (
//             <div className="flex items-center justify-center h-40">
//               <p className="text-gray-500 text-center">
//                 No notes available, Please add Notes{" "}
//               </p>
//             </div>
//           )}
//         </div>
//         <div className="mt-auto pt-3 pr-4">
//           <form onSubmit={handleSubmit} className="w-full">
//             <div className="relative w-full ">
//               <textarea
//                 placeholder="Type your notes here..."
//                 className="w-full px-4 py-2 pr-8 border border-slate-200 rounded-lg focus:outline-none focus:ring-1 focus:ring-slate-300 resize-none h-10 bg-slate-100 "
//                 onChange={(e) => setNotes(e.target.value)}
//                 value={notes}
//                 onKeyDown={(e) => {
//                   if (e.key === "Enter" && !e.shiftKey) {
//                     e.preventDefault();
//                     handleSubmit(e);
//                   }
//                 }}
//               />
//               <button
//                 type="submit"
//                 className={`absolute right-6 bottom-3 text-blue-500 hover:text-blue-700 ${
//                   isSubmitted ? "cursor-not-allowed" : ""
//                 }`}
//                 disabled={isSubmitted}
//               >
//                 ➤
//               </button>
//             </div>
//           </form>
//         </div>
//       </div>
//     </div>
//   );
// };

const PromptSummaryPopup = (props) => {
  const { file, selectedRecords, closePopup, selectedDate } = props;
  console.log({ selectedDate })
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
      <div className="bg-white p-4 rounded-lg mx-10 max-h-[80vh] overflow-hidden flex flex-col ">
        <div className="w-full flex justify-between items-center mb-2  flex-shrink-0 sticky top-0 z-30 bg-white ">
          {/* <p className="font-medium text-lg ">Generate New Insights for {file_name} - {file_type} </p> */}
          <div>
            <p className="text-lg font-semibold text-gray-800 ">
              <span className="text-gray-700"> Insights for </span>
              <span className="font-bold text-black underline">
                {file?.file_name}
              </span>
              <span className="text-gray-500"> - {file?.file_type + " - "}</span>
              <span className="text-gray-500">
                {/* {format(new Date(selectedDate), "dd-MM-yyyy ")} */}
                {selectedDate === "Default Record" ? selectedDate : format(new Date(selectedDate), "dd-MM-yyyy ")}
              </span>
            </p>
          </div>
          <button onClick={() => closePopup()}>
            <svg
              className="w-6 h-6 text-gray-800 "
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="m15 9-6 6m0-6 6 6m6-3a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
              />
            </svg>
          </button>
        </div>
        <div className="p-2 flex-grow w-full  overflow-y-auto ">
          {selectedRecords.length > 0 && (
            <div>
              {selectedRecords.map((record, index) => (
                <div
                  key={index}
                // className="mb-4 p-2 border rounded-lg bg-gray-50"
                >
                  <div className="flex justify-end px-2 mb-2">
                    <div className="bg-slate-200 rounded-md p-2 self-end w-fit max-w-[75%]">
                      <p className="">{record?.prompt}</p>
                    </div>
                  </div>
                  <div className="mt-4 mb-4 mr-36 flex justify-start px-2">
                    <div className="  p-2 bg-gray-300 rounded-md break-words leading-relaxed">
                      <p>{record?.summary}</p>
                      <span className="text-black italic text-xs ">
                        {format(
                          new Date(record?.created_at),
                          "dd-MM-yyyy HH:mm:ss"
                        ) + " IST"}
                      </span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const PatientSummary = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setIsLoading] = useState(false);
  const [file, setFile] = useState(null);
  const [isPromptPopupVisible, setIsPromptPopupVisible] = useState(false);
  // const [userdayWiseData, setUserdayWiseData] = useState([]);
  // const [defaultRecord, setDefaultRecord] = useState("");
  const [allRecords, setAllRecords] = useState([]);
  const [selectedRecords, setSelectedRecords] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  //  const [isNotesPopup, setIsNotesPopup] = useState(false);
  //  const [notesList, setNotesList] = useState([]);
  //const [note, setNote] = useState("");
  // const [submitting, setSubmitting] = useState(false);
  // const [selectedNoteId, setSelectedNoteId] = useState(null);

  const {
    users,
    isSidebarOpen,
    setIsSidebarOpen,
    enquires,
    setEnquiresIdentity,
    isNotesPopup,
    setIsNotesPopup,
  } = useContext(UserContext);

  const { id } = useParams();
  const navigate = useNavigate();
  const token = users?.token;
  const name = users?.first_name;
  const roles = { 1: "Admin", 2: "Doctor", 3: "Patient" };
  const role = roles[users?.role_id];
  const summaryHeaders = [
    "#",
    "Summary",
    //   "Notes",
    "Created",
    // "Created By",
  ];

  // Function to truncate text
  const truncateText = (text, length) =>
    text.length > length ? `${text.substring(0, length)}...` : text;

  useEffect(() => {
    if (!token) {
      navigate("/login");
    }
  }, [token, navigate]);

  useEffect(() => {
    const storedFile = localStorage.getItem("selectedFile");
    if (storedFile) {
      setFile(JSON.parse(storedFile));
    }
  }, []);

  useEffect(() => {
    closeSidebar();
  }, [id]);

  const fetchAiSummaries = async (file) => {
    if (!file) return;
    setErrorMessage("");
    setIsLoading(true);

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_NEW_API_URL}/get-ai-summaries-v1?file_id=${file?.file_id}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );

      console.log("response for all summaries for specific id", response);

      if (response.data.status === 200) {
        const data = response?.data?.data;
        const { dayWiseRecords, defaultRecord } = data;

        //const dayWiseRecordsData = Object.entries(dayWiseRecords);

        // Convert `dayWiseRecords` into an array of objects with { date, records }
        const dayWiseRecordsArray = Object.entries(dayWiseRecords).map(
          ([date, records]) => ({
            date,
            records,
          })
        );
        // Ensure `defaultRecord` is the first item in the final array
        const records = [
          { date: "Default Record", records: [defaultRecord] },
          ...dayWiseRecordsArray,
        ];

        console.log("Structured Records:", records);
        setAllRecords(records);

        //day wise records
        //      setUserdayWiseData(dayWiseRecordsData);
        //default record
        //    setDefaultRecord(defaultRecord);
      }
      if (response.data.status === 400) {
        const genaratedSummaries = await generateAiSummaries(
          file.file_id,
          file.file_name,
          name,
          role
        );
        console.log(genaratedSummaries);

        if (genaratedSummaries) {
          await fetchAiSummaries(file);
        } else {
          console.log(genaratedSummaries?.message);
          setErrorMessage(genaratedSummaries?.message);
        }
      }
    } catch (error) {
      console.error("Error fetching summaries:", error);
      if (error.status === 400) {
        navigate("/login");
      }
    } finally {
      setIsLoading(false);
    }
  };

  const generateAiSummaries = async (fileId, filename, name, role) => {
    try {
      const payload = {
        file_id: fileId,
        document_path: `${enquires?.identity}/${filename}`,
        created_by: `${name}-${role}`,
      };

      const response = await axios.post(
        `${process.env.REACT_APP_NEW_API_URL}/generate-ai-summaries`,
        payload,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      console.log(response?.data?.allSummaries?.data);
      return response?.data?.allSummaries?.data;
    } catch (error) {
      console.error("Error generating summaries:", error);
    }
  };

  useEffect(() => {
    fetchAiSummaries(file);
  }, [file?.id]);

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

  const openSidebar = () => {
    setEnquiresIdentity(enquires?.identity);
    setIsSidebarOpen(true);
  };

  const closeSidebar = () => {
    setIsSidebarOpen(false);
  };

  const addNewPromptPopup = () => {
    setIsPromptPopupVisible(true);
  };

  const closePromptPopup = () => {
    setIsPromptPopupVisible(false);
  };

  const handleReadMore = (date, records) => {
    console.log({ date, records });
    setShowPopup(true);
    setSelectedDate(date);
    setSelectedRecords(records);
  };

  const closePopup = () => {
    setShowPopup(false);
    // setSelectedDate(null);
    setSelectedRecords([]);
  };

  const openNotesPopup = () => {
    setIsNotesPopup(true);
  };

  const closeNotes = () => {
    setIsNotesPopup(false);
  };


  useEffect(() => {
    if (isSidebarOpen || isPromptPopupVisible || showPopup || isNotesPopup) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }

    return () => {
      document.body.classList.remove("overflow-hidden");
    };
  }, [isSidebarOpen, isPromptPopupVisible, showPopup, isNotesPopup]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [isSidebarOpen]);

  return (
    <div>
      <Header toggleMenu={toggleMenu} />
      <div className="flex min-h-[calc(100vh-90px)] overflow-x-hidden">
        <Sidebar isMenuOpen={isMenuOpen} />
        <div className="flex-grow bg-[#F9F8F8] px-4   font-poppins min-h-[calc(100vh-90px)] overflow-x-hidden ">
          <div className="w-full flex flex-col items-start justify-start gap-3 pt-2 overflow-x-auto min-h-[100vh]">
            <h3 className="text-lg font-semibold">Document Insights</h3>
            <div className="w-full overflow-x-auto">
              <table className="w-full min-w-[600px] border border-[#c0c0c0]">
                <tbody>
                  <tr className="bg-[#E9F0FF] text-xs md:text-sm lg:text-base font-medium">
                    <td
                      className="px-2 md:px-3 py-2 md:py-[9.5px] text-blue-500 underline underline-offset-4 hover:text-blue-700 cursor-pointer"
                      onClick={() => openSidebar()}
                      role="button"
                      tabIndex={0}
                    >
                      {enquires?.name}
                    </td>
                    <td className="px-2 md:px-3 py-2 md:py-[9.5px]">
                      {enquires?.email_id}
                    </td>
                    <td className="px-2 md:px-3 py-2 md:py-[9.5px]">
                      {enquires?.phone}
                    </td>
                    <td className="px-2 md:px-3 py-2 md:py-[9.5px]">
                      {file?.file_type}
                    </td>
                    <td className="px-2 md:px-3 py-2 md:py-[9.5px] font-semibold underline cursor-text">
                      {file?.file_name}
                    </td>
                    <td className="px-2 md:px-3 py-2 md:py-[9.5px]">
                      <span className="italic text-xs">Uploaded by </span>
                      <span className="text-sm">
                        {file?.added_by_name} on
                        {/* {format(new Date(file.created_at)," dd-MM-yyyy HH:mm:ss ")+ "IST "} */}
                        {file?.created_at
                          ? format(
                            new Date(file.created_at),
                            " dd-MM-yyyy HH:mm:ss"
                          ) + " IST"
                          : "N/A"}
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className="w-full h-full">
              <div className="w-full  flex justify-between items-center my-5 ">
                <p className="text-lg font-semibold underline underline-offset-4 ">
                  Genarated AI Insights
                </p>
                <div className="flex flex-row  gap-4 text-center ">
                  {/* <button
                    className="bg-blue-500 text-white p-2 rounded hover:bg-blue-600 text-sm"
                    onClick={() => openNotesPopup()}
                  >
                    Notes{" "}
                  </button> */}
                  <Link
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      openNotesPopup(file);
                    }}
                    className="text-blue-500 underline hover:text-blue-700 focus:outline-none flex items-center"
                  >
                    Notes
                  </Link>

                  <Link
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      addNewPromptPopup();
                    }}
                    className="text-blue-500 underline hover:text-blue-700 focus:outline-none flex items-center"
                  >
                    Add New Prompt
                  </Link>
                </div>
              </div>
              {loading ? (
                <div className="flex justify-center w-full mt-10">
                  <svg
                    className="inline w-7 h-7 text-[#4838C8] animate-spin fill-white"
                    viewBox="0 0 100 101"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill="currentColor"
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    />
                    <path
                      fill="currentFill"
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    />
                  </svg>
                </div>
              ) : allRecords.length > 0 ? (
                <div className="overflow-x-auto my-3 w-full">
                  <table className="w-full table-auto  border border-gray-300">
                    <thead>
                      <tr>
                        {summaryHeaders.map((header, index) => (
                          <th
                            key={index}
                            className="bg-[#E9F0FF] px-3 py-2 text-left text-xs md:text-sm lg:text-base font-medium border border-gray-400"
                          >
                            {header}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-300">
                      {/* Default Record (First Row) */}
                      {allRecords.length > 0 && (
                        <tr className="divide-x divide-[#c0c0c0] border-x border-[#c0c0c0]  rounded-b-md text-start text-sm">
                          <td className="py-2 px-2">Default</td>
                          <td className="py-2 px-4">
                            <span>
                              {" "}
                              {truncateText(
                                allRecords[0].records[0].summary,
                                200
                              )}
                            </span>

                            <button
                              className="text-blue-600 ml-2 underline text-xs"
                              onClick={() =>
                                handleReadMore(
                                  allRecords[0].date,
                                  allRecords[0].records
                                )
                              }
                            >
                              <span className="text-xs">Read More</span>
                            </button>
                          </td>
                          <td className="py-2 px-2">
                            {format(
                              new Date(allRecords[0].records[0].created_at),
                              "dd-MM-yyyy HH:mm:ss"
                            )}{" "}
                            IST by {allRecords[0].records[0].created_by}
                          </td>
                        </tr>
                      )}
                      {/* Day-wise Records */}
                      {allRecords.slice(1).map(({ date, records }, index) => {
                        const latestRecord = records[0]; // Show only the latest record

                        return (
                          <React.Fragment key={date}>
                            {/* Show Latest Record for Each Date */}
                            <tr className="divide-x divide-[#c0c0c0] border-x border-[#c0c0c0]  rounded-b-md text-start text-sm">
                              <td className="py-2 px-2">{index + 1}</td>
                              <td className="py-2 px-4">
                                <span>
                                  {truncateText(latestRecord.summary, 120)}
                                </span>
                                <button
                                  className="text-blue-600 ml-2 underline text-xs"
                                  onClick={() => handleReadMore(date, records)}
                                >
                                  Read More
                                </button>
                              </td>
                              <td className="py-2 px-2 ">
                                {format(
                                  new Date(latestRecord.created_at),
                                  "dd-MM-yyyy HH:mm:ss"
                                )}{" "}
                                IST by {latestRecord.created_by}
                              </td>
                            </tr>
                          </React.Fragment>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              ) : (
                <div className="w-full flex justify-center items-center ">
                  {errorMessage && (
                    <p className="  text-gray-500 text-lg md:text-2xl my-10">
                      {errorMessage}
                    </p>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>

        {showPopup && (
          <PromptSummaryPopup
            file={file}
            selectedRecords={selectedRecords}
            closePopup={closePopup}
            selectedDate={selectedDate}
          />
        )}

        {isNotesPopup && <NotesPopup file={file} closeNotes={closeNotes} />}

        {isSidebarOpen && <PatientDetails closeSidebar={closeSidebar} />}
        {isPromptPopupVisible && (
          <AddNewPromptSummaryPopup
            closePrompt={closePromptPopup}
            fetchAiSummaries={fetchAiSummaries}
            file={file}
          />
        )}
      </div>
    </div>
  );
};

export default PatientSummary;
