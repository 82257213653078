import { useState, useEffect, useCallback, useContext } from "react";
import axios from "axios";
//import { UserContext } from "../Context/UserContext";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";

const useSpecializations = () => {
  const [specializations, setSpecializations] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchSpecialization = async () => {
      try {
        const url = `${process.env.REACT_APP_NEW_API_URL}/specializations`;
        const response = await axios.get(url);
        console.log("specializations api:", response?.data?.data);

        const data = response?.data?.data?.map((item) => ({
          value: item.id,
          label: item.specialization,
        }));

        setSpecializations(data);
      } catch (error) {
        console.error("Error fetching specializations:", error);
        if (error?.response?.status === 400) {
          navigate("/login");
          toast.error("Session expired,Please login again");
        }
      }
    };

    fetchSpecialization();
  }, []);

  return { specializations };
};

export default useSpecializations;
