import React, { useState, useCallback, useContext, useEffect } from "react";
import { useNavigate } from "react-router";
import Header from "../OpenaiScreeens/Header";
import Sidebar from "../OpenaiScreeens/Sidebar";

import { UserContext } from "../Context/UserContext";
import axios from "axios";
import { toast } from "react-toastify";

const PatientForm = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    emailId: "",
    phoneNumber: "",
    postalCode: "",
    role: 3,
  });

  const navigate = useNavigate();

  const { users } = useContext(UserContext);
  const token = users?.token;


  const roles = [
    {
      role_id: 1,
      role_name: "Admin",
    },
    {
      role_id: 2,
      role_name: "Doctor",
    },
    {
      role_id: 3,
      role_name: "Patient",
    },
  ];

  useEffect(() => {
    if (!token) {
      navigate("/login");
      return;
    }
  }, [token, navigate]);



  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true)
    try {
      const payload = {
        first_name:
          formData.firstName.charAt(0).toUpperCase() +
          formData.firstName.slice(1),
        last_name:
          formData.lastName.charAt(0).toUpperCase() +
          formData.lastName.slice(1),
        email_id: formData.emailId.trim(),
        phone_number: formData.phoneNumber,
        postal_code: formData.postalCode,
        role_id: Number(formData.role),
        created_by: roles.find(
          (role) => role.role_id.toString() === formData.role.toString()
        )?.role_name,
      };

      console.log(payload)
      const response = await axios.post(
        `${process.env.REACT_APP_NEW_API_URL}/add-new-user`,
        payload,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      console.log(response);

      if (response?.status === 200) {
        setFormData({
          firstName: "",
          lastName: "",
          emailId: "",
          phoneNumber: "",
          postalCode: ""
        });
      }
    } catch (error) {
      console.log(error);
      if (error?.status === 400) {
        navigate("/login");
        toast.error("Session expired,Please login again");
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div>
      <Header toggleMenu={toggleMenu} />
      <div className="flex min-h-screen">
        {/* Sidebar */}
        <Sidebar isMenuOpen={isMenuOpen} />
        <div className="  w-full mx-auto pb-8 px-5 sm:px-12 lg:px-10 font-poppins ">
          <h2 className="font-medium text-2xl py-5">Add New Patient</h2>

          <form onSubmit={(e) => handleSubmit(e)}>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-12 gap-y-5 py-3">
              <div>
                <label htmlFor="firstName" className="font-medium">
                  First Name
                  <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  id="firstName"
                  name="firstName"
                  placeholder="jhon"
                  className="w-full p-2.5 text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300"
                  value={formData.firstName}
                  onChange={(e) => {
                    const value = e.target.value
                      .trimStart() // removes any whitespace from the beginning
                      .replace(/[^a-zA-Z\s]/g, "");
                    setFormData((prevData) => ({
                      ...prevData,
                      firstName: value,
                    }));
                  }}
                  required
                />
              </div>
              <div>
                <label htmlFor="firstName" className="font-medium">
                  Last Name
                  <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  id="lastName"
                  name="lastName"
                  placeholder="doe"
                  className="w-full p-2.5 text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300"
                  value={formData.lastName}
                  onChange={(e) => {
                    const value = e.target.value
                      .trimStart() // removes any whitespace from the beginning
                      .replace(/[^a-zA-Z\s]/g, "");
                    setFormData((prevData) => ({
                      ...prevData,
                      lastName: value,
                    }));
                  }}
                  required
                />
              </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-12 gap-y-5 py-3">
              <div>
                <label htmlFor="emailId" className="font-medium">
                  Email Address
                  <span className="text-red-500">*</span>
                </label>
                <input
                  type="email"
                  placeholder="email@company.com"
                  className="w-full p-2.5 text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300"
                  id="emailId"
                  name="emailId"
                  value={formData.emailId}
                  onChange={(e) => {
                    const value = e.target.value
                      .replace(/^\s+/, "")
                      .replace(/[^a-zA-Z0-9@.]/g, "");
                    setFormData((prevData) => ({
                      ...prevData,
                      emailId: value,
                    }));
                  }}
                />
              </div>
              <div>
                <label htmlFor="phoneNumber" className="font-medium">
                  Phone Number
                  <span className="text-red-500">*</span>
                </label>

                <input
                  type="tel"
                  id="phoneNumber"
                  name="phoneNumber"
                  value={formData.phoneNumber}
                  onChange={(e) => {
                    const value = e.target.value.replace(/[^0-9]/g, "");
                    setFormData((prevData) => ({
                      ...prevData,
                      phoneNumber: value,
                    }));
                  }}
                  className="w-full p-2.5 text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-primary-500 focus:border-primary-500"
                  placeholder="9876543210"
                  minLength={10}
                  maxLength={10}
                  required
                />
              </div>

            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-12 gap-y-5 py-3">
              <div>
                <label className="font-medium" htmlFor="postalCode">
                  Postal Code
                  <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  id="postalCode"
                  name="postalCode"
                  value={formData.postalCode}
                  onChange={(e) => {
                    const value = e.target.value.replace(/[^0-9]/g, "");
                    setFormData((prevData) => ({
                      ...prevData,
                      postalCode: value,
                    }));
                  }}
                  className="w-full p-2.5 text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 "
                  placeholder="123456"
                  minLength={6}
                  maxLength={6}
                  required
                />
              </div>
              {/* <div>
                <label className=" font-medium">Role</label>
                <span className="text-red-500">*</span>
                <select
                  name="role"
                  value={formData.role}
                  onChange={(e) => {
                    const newRole = e.target.value;
                    setFormData((prevData) => ({
                      ...prevData,
                      role: newRole,
                    }));
                  }}
                  className="w-full p-2.5 text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 cursor-not-allowed "
                  disabled
                >
                  <option value="" disabled>
                    Select Role
                  </option>
                  {roles
                    .filter((role) => role.role_id === 3)
                    .map((role) => (
                      <option key={role.role_id} value={role.role_id}>
                        {role.role_name}
                      </option>
                    ))}
                </select>
              </div> */}
              <div>
                <label className=" font-medium">Role</label>
                <span className="text-red-500">*</span>
                <select
                  name="role"
                  value={formData.role}
                  className="w-full p-2.5 text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 cursor-not-allowed "
                  disabled
                >
                  <option value="3">Patient</option>

                </select>
              </div>

            </div>
            <div className="text-center flex justify-start gap-5 py-5">
              <button
                onClick={() => navigate("/admin/patients")}
                type="button"
                className="px-5 py-2 font-medium text-secondary text-sm rounded-md border border-[#4838C8] cursor-pointer"
              >
                Back
              </button>
              <button
                className="bg-[#4838C8] font-medium text-white py-2 text-sm px-5 rounded-md"
                type="submit"
                disabled={isSubmitting}
              >
                {isSubmitting ? (
                  <svg
                    aria-hidden="true"
                    className="inline w-4 h-4 text-white animate-spin  fill-secondary"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="currentColor"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentFill"
                    />
                  </svg>
                ) : (
                  "Submit"
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default PatientForm;
