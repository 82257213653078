import React, { useContext, useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import cognismilesLogo from "../assets/cognismiles-logo.png";
import { UserContext } from "../Context/UserContext";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import ForgotPassword from "./ForgotPassword";
import { toast } from "react-toastify";

const LoginForm = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState({ login: "", otp: "" });
  const [otpSent, setOtpSent] = useState(false);
  const [otp, setOtp] = useState("");
  const [maskedEmail, setMaskedEmail] = useState("");
  const { setUsers } = useContext(UserContext);
  const { executeRecaptcha } = useGoogleReCaptcha();
  const navigate = useNavigate();
  const [formDisabled, setFormDisabled] = useState(false);
  const [resendOtpMessage, setResendOtpMessage] = useState({
    show: false,
    message: {},
  });
  const [isSubmitting, setIsSubmitted] = useState(false);
  const otpSectionRef = useRef(null);

  // Resend OTP Controls
  const [resendTimer, setResendTimer] = useState(60);
  const [isResend, setIsResend] = useState(true);

  useEffect(() => {
    let timer;
    if (isResend && otpSent) {
      timer = setInterval(() => {
        setResendTimer((prev) => {
          if (prev <= 1) {
            clearInterval(timer);
            setIsResend(false);
            return 60;
          }
          return prev - 1;
        });
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [isResend, otpSent]);

  //scrolling to otp section
  useEffect(() => {
    if (otpSent && otpSectionRef.current) {
      otpSectionRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [otpSent]);

  // Function to mask the email
  const maskEmail = (email) => {
    if (!email) return "";

    const [localPart, domain] = email.split("@");
    if (!domain) return email;

    const firstTwo = localPart.slice(0, 2);
    const lastTwo = localPart.slice(-2);

    const maskedLocal =
      localPart.length > 6
        ? firstTwo + localPart.slice(2, -2).replace(/./g, "X") + lastTwo
        : localPart[0] +
          localPart.slice(1, -1).replace(/./g, "X") +
          localPart.slice(-1);

    return `${maskedLocal}@${domain}`;
  };

  useEffect(() => {
    if (email) {
      setMaskedEmail(maskEmail(email));
    }
  }, [email]);
  //handling login
  const handleLogin = async (e) => {
    e.preventDefault();
    setErrorMessage("");
    setIsSubmitted(true);

    //captcha
    if (!executeRecaptcha) {
      setErrorMessage((prev) => ({
        ...prev,
        login: "reCAPTCHA is not available. Please try again later.",
      }));
      return;
    }

    try {
      const captchaToken = await executeRecaptcha("login");
      if (!captchaToken) {
        setErrorMessage((prev) => ({
          ...prev,
          login: "Failed to generate reCAPTCHA token. Please try again.",
        }));
        return;
      }

      const captchaResponse = await axios.post(
        `${process.env.REACT_APP_NEW_API_URL}/verify-recaptcha`,
        {
          token: captchaToken,
        }
      );

      if (captchaResponse?.data?.response?.data?.success !== true) {
        setErrorMessage((prev) => ({
          ...prev,
          login: "reCAPTCHA verification failed. Please try again.",
        }));
        return;
      }

      //login

      const loginResponse = await axios.post(
        `${process.env.REACT_APP_NEW_API_URL}/login`,
        {
          email_id: email.trim(),
          password: password.trim(),
        }
      );

      if (loginResponse?.status === 200) {
        toast.success("OTP sent to your email ")
        setOtpSent(true);
        setFormDisabled(true);
      }
    } catch (error) {
      setErrorMessage((prev) => ({
        ...prev,
        login: error?.response?.data?.result?.message,
      }));
    } finally {
      setIsSubmitted(false);
    }
  };
  //handling otp submit
  const handleOtpSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage((prev) => ({
      ...prev,
      otp: "",
    }));
    if (!otp) {
      setErrorMessage((prev) => ({
        ...prev,
        otp: "Please enter the OTP.",
      }));
      return;
    }

    try {
      const otpResponse = await axios.post(
        `${process.env.REACT_APP_NEW_API_URL}/verify-login-otp`,
        {
          email: email.trim(),
          otp,
        }
      );
      console.log(otpResponse);
      console.log(otpResponse?.data?.message);
      if (otpResponse?.data?.status === 200) {
        console.log(otpResponse?.data?.data);
        const userData = otpResponse?.data?.data;
        setUsers(userData);
        const roleId = otpResponse?.data?.data?.role_id;

        console.log(roleId);
        toast.success("Login successfull")
        if (roleId === 1) {
          navigate(`/admin`);
        } else if (roleId === 2) {
          navigate(`/profile/doctor`);
        } else if (roleId === 3) {
          navigate(`/profile/patient`);
        }
      } else {
        setErrorMessage((prev) => ({
          ...prev,
          otp: otpResponse?.data?.message,
        }));
      }
    } catch (error) {
      console.log(error);
      setErrorMessage((prev) => ({
        ...prev,
        otp: error?.response?.data?.message,
      }));
    }
  };
  //handling reset otp
  const resendOtp = async () => {
    const resendPayload = {
      email: email.trim(),
      purpose: 1,
    };
    try {
      const resendOtpResponse = await axios.post(
        `${process.env.REACT_APP_NEW_API_URL}/resend-otp`,
        resendPayload
      );
      console.log(resendOtpResponse);
      if (resendOtpResponse.status === 200) {
        setOtpSent(true);
        setIsResend(true);
        console.log(resendOtpResponse?.data);
        setResendOtpMessage({
          show: true,
          message: "OTP resent successfully!",
        });

        setTimeout(() => {
          setResendOtpMessage({ show: false, message: "" });
        }, 10000);
      }
    } catch (error) {
      console.log("resend otp", error?.response?.data?.error);
      setErrorMessage((prev) => ({
        ...prev,
        otp: error?.response?.data?.message,
      }));
    }
  };

  return (
    <section className="bg-[#F9F8F8] min-h-screen flex items-center justify-center px-4 py-8 sm:px-6 lg:px-8 font-poppins">
      <div className="w-full max-w-md space-y-8">
        <div
          className={`bg-white rounded-lg shadow-md p-6 sm:p-8 ${
            formDisabled ? "opacity-50 pointer-events-none" : ""
          }`}
        >
          <img
            className="w-[100px] h-auto mx-auto"
            src={cognismilesLogo}
            alt="logo"
          />

          <h1 className="text-xl font-medium text-gray-800 text-center mb-6">
            Sign in to your account
          </h1>
          <form onSubmit={handleLogin} className="space-y-6">
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700 mb-1"
              >
                Your email
              </label>
              <input
                type="email"
                id="email"
                className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-black focus:border-black"
                placeholder="johnDoe@example.com"
                value={email}
                onChange={(e) => {
                  setErrorMessage("");
                  setEmail(e.target.value);
                }}
                required
                disabled={formDisabled}
              />
            </div>
            <div>
              <label
                htmlFor="password"
                className="block text-sm lg:text-lg font-medium text-gray-700 mb-1"
              >
                Password
              </label>
              <div className="relative">
                <input
                  type={showPassword ? "text" : "password"}
                  id="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-black focus:border-black"
                  placeholder="••••••••"
                  disabled={formDisabled}
                />
                <button
                  type="button"
                  onClick={() => setShowPassword(!showPassword)}
                  className="absolute inset-y-0 right-0 flex items-center pr-3"
                  disabled={formDisabled}
                >
                  {showPassword ? (
                    <svg
                      className="w-6 h-6 text-gray-400 cursor-pointer"
                      aria-label="Hide password"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        stroke="currentColor"
                        strokeWidth="2"
                        d="M21 12c0 1.2-4.03 6-9 6s-9-4.8-9-6c0-1.2 4.03-6 9-6s9 4.8 9 6Z"
                      />
                      <path
                        stroke="currentColor"
                        strokeWidth="2"
                        d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                      />
                    </svg>
                  ) : (
                    <svg
                      className="w-6 h-6 text-gray-400 cursor-pointer"
                      aria-label="Show password"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M3.933 13.909A4.357 4.357 0 0 1 3 12c0-1 4-6 9-6m7.6 3.8A5.068 5.068 0 0 1 21 12c0 1-3 6-9 6-.314 0-.62-.014-.918-.04M5 19 19 5m-4 7a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                      />
                    </svg>
                  )}
                </button>
              </div>
            </div>
            {errorMessage?.login && (
              <p className="text-red-500 text-sm">{errorMessage?.login}</p>
            )}

            <p
              className="text-sm font-medium text-primary hover:underline  cursor-pointer"
              onClick={() => {
                console.log("Navigating to Forgot Password...");
                navigate("/forgot-password")
              }}
            >
              Forgot password?
            </p>
            {/* <button
              type="submit"
              className="w-full px-8 py-2.5 text-white bg-[#4838C8] font-medium rounded-lg text-sm text-center"
              disabled={formDisabled}
            >
              Submit
            </button> */}
            {/* <div className="flex items-center justify-between">
              <button
                type="button"
                className="text-sm font-medium text-primary-600 hover:underline cursor-pointer"
                onClick={() => {
                  console.log("Navigating to Forgot Password...");
                  navigate("/forgot-password")
                }}
              >
                Forgot password
              </button>
             
            </div> */}
            <button
              type="submit"
              disabled={isSubmitting}
              className={`w-full px-8 py-2.5 text-white bg-[#4838C8] focus:ring-4 focus:outline-none focus:ring-[#4838C8] font-medium rounded-lg text-sm text-center flex items-center justify-center ${
                isSubmitting ? " cursor-not-allowed" : ""
              }`}
            >
              {isSubmitting ? (
                <svg
                  className="inline w-7 h-7 text-[#4838C8] animate-spin fill-white"
                  viewBox="0 0 100 101"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill="currentColor"
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  />
                  <path
                    fill="currentFill"
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  />
                </svg>
              ) : (
                "Submit"
              )}
            </button>
          </form>
          <p className="mt-4 text-center text-sm text-gray-600">
            Don't have an account yet?{" "}
            <button
              onClick={() => navigate("/register")}
              className="font-medium text-[#4838C8] hover:underline"
            >
              Sign up
            </button>
          </p>
        </div>
        {otpSent && (
          <div
            ref={otpSectionRef}
            className="bg-white rounded-lg shadow-md p-6 sm:p-8 my-8"
          >
            <h2 className="text-lg font-medium text-gray-800 text-center mb-4">
              OTP Verification
            </h2>
            <p className="text-sm text-gray-600 text-center mb-4">
              Enter the OTP sent to{" "}
              <strong className="font-bold text-black">{maskedEmail}</strong>
            </p>
            <form onSubmit={handleOtpSubmit} className="space-y-4">
              <div>
                <label
                  htmlFor="otp"
                  className="block text-sm lg:text-lg font-medium text-gray-700 mb-1"
                >
                  OTP
                </label>
                <input
                  type="text"
                  id="otp"
                  className="w-full px-3 py-2 border border-[#F2F4F4] rounded-md shadow-sm focus:ring-black focus:border-black"
                  value={otp}
                  onChange={(e) => {
                    setOtp(e.target.value);
                    setErrorMessage((prev) => ({ ...prev, otp: "" })); // Clear OTP error message
                  }}
                  placeholder="Enter OTP"
                />
              </div>
              {errorMessage?.otp && (
                <p className="text-red-500 text-sm">{errorMessage?.otp}</p>
              )}
              {resendOtpMessage.show && (
                <p className="text-green-800 text-sm">
                  {resendOtpMessage.message}
                </p>
              )}
              <button
                type="submit"
                className="w-full px-8 py-2.5 text-white bg-[#4838C8] font-medium rounded-lg text-sm text-center"
              >
                Verify OTP
              </button>
            </form>
            <div className="mt-4 text-center">
              <span className="text-gray-500">Didn't receive OTP? </span>
              <button
                type="button"
                className={`cursor-pointer ${
                  isResend
                    ? "text-gray-500 opacity-50 cursor-not-allowed"
                    : "text-[#4838c8]"
                }`}
                onClick={resendOtp}
                disabled={isResend}
              >
                {isResend ? `Resend in ${resendTimer}s` : "Resend OTP"}
              </button>
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default LoginForm;
