import { useState, useEffect,  useContext } from "react";
import axios from "axios";
import { UserContext } from "../Context/UserContext";

import { useNavigate } from "react-router";
import { toast } from "react-toastify";

const useEnquiresList = () => {
   const [enquiresList, setEnquiresList] = useState([]);
  const[loading,setLoading] = useState(false)
  const { users } = useContext(UserContext);
  const token = users?.token;
  const navigate = useNavigate()



  useEffect(() => {
    if (!token) {
      navigate("/login");
    }
  }, [navigate, token]);

  const getEnquiresList = async () => {
    if (!token) {
      return;
    }
    setLoading(true)
    try {
      const url = `${process.env.REACT_APP_NEW_API_URL}/get-all-enquiries`;
      const response = await axios.get(url, {
        headers: { Authorization: `Bearer ${token}` },
      });

      console.log(response?.data?.data.details);
      if (response.status === 200) {
        const newData = response.data.data.details;

        setEnquiresList(newData);
      }
    } catch (error) {
      console.log("fetching clinic details", error);
      if (error.response?.status === 400) {
        navigate("/login");
         toast.error("Session expired,Please login again")
      }
    } 
    finally {
        setLoading(false)
    }
  }

  useEffect(() => {
    getEnquiresList()
  },[token]);

  return { enquiresList,loading};
};

export default useEnquiresList



