// import React, { createContext, useEffect, useState,useCallback   } from "react";

// export const UserContext = createContext();

//  const UserProvider = ({ children }) => {
//   const [users, setUsers] = useState(() => {
//     const storedUser = sessionStorage.getItem("users");
//     return storedUser ? JSON.parse(storedUser) : null;
//   });

//    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
//    const [enquiresIdentity, setEnquiresIdentity] = useState(null);

//   useEffect(() => {
//     if (users) {
//       sessionStorage.setItem("users", JSON.stringify(users));
//     } else {
//       sessionStorage.removeItem("users");
//     }
//   }, [users]);

//   const logout = useCallback(() => {
//     setUsers(null)
//   }, [setUsers])

//   return (
//     <UserContext.Provider value={{ users, setUsers,logout ,isSidebarOpen, setIsSidebarOpen,enquiresIdentity, setEnquiresIdentity}}>
//       {children}
//     </UserContext.Provider>
//   );
// };

//  export default UserProvider;

import React, { createContext, useEffect, useState, useCallback } from "react";

export const UserContext = createContext();

const UserProvider = ({ children }) => {
  // Persist users in localStorage
  const [users, setUsers] = useState(() => {
    const storedUser = localStorage.getItem("users");
    return storedUser ? JSON.parse(storedUser) : null;
  });

  const [enquires, setEnquires] = useState(() => {
    const storedEnquires = localStorage.getItem("enquires");
    return storedEnquires ? JSON.parse(storedEnquires) : [];
  });

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [enquiresIdentity, setEnquiresIdentity] = useState(null);
   const [isNotesPopup, setIsNotesPopup] = useState(false);

  // Sync users state with localStorage
  useEffect(() => {
    if (users) {
      localStorage.setItem("users", JSON.stringify(users));
    } else {
      localStorage.removeItem("users");
    }
  }, [users]);

  useEffect(() => {
    if (enquires) {
      localStorage.setItem("enquires",JSON.stringify(enquires))
    }
    else {
      localStorage.removeItem("enquires")
    }
  })

  const logout = useCallback(() => {
    setUsers(null);
    setIsSidebarOpen(false);
    setEnquiresIdentity(null);
  }, []);

  return (
    <UserContext.Provider
      value={{
        users,
        setUsers,
        logout,
        isSidebarOpen,
        setIsSidebarOpen,
        enquiresIdentity,
        setEnquiresIdentity,
        enquires,
        setEnquires,
        isNotesPopup, 
        setIsNotesPopup
        
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserProvider;
