import { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const useClinicList = () => {
  const [clinicsList, setClinicList] = useState([]);

  const navigate= useNavigate()

  const getClinicsList = async () => {
    try {
      const url = `${process.env.REACT_APP_NEW_API_URL}/get-all-clinics`;
      const response = await axios.get(url);

      console.log(response?.data?.data);
      if (response.status === 200) {
        const data = response?.data?.data?.map((item) => ({
          id: item.id,
          value: item.clinic_id,
          label: item.clinic_name,
        }));
        setClinicList(data);
      }
    } catch (error) {
      console.log("Error fetching clinic details:", error);
      if (error?.response?.status === 400) {
        navigate("/login")
         toast.error("Session expired,Please login again")
      }
    }
  };

  useEffect(() => {
    getClinicsList();
  }, []); // Empty dependency array ensures this runs only once when the component mounts

  
  return { clinicsList };
};

export default useClinicList;
