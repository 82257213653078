import React, { useContext, useState } from "react";
//import useFetchUploadedFiles from "../hooks/useFetchUploadedFiles";
import { UserContext } from "../Context/UserContext";
import { Link } from "react-router-dom";
import axios from "axios";
import NotesPopup from "./Notes";
import { format } from "date-fns";

const UploadedFiles = ({ uploadedFiles, loading }) => {
  //const[isOpenModal,setIsModalOpen]  = useState(false)
  const [isUplodedFileVisible, setIsUplodedVisible] = useState(false);
  const [file, setFiles] = useState("");
  const { users, enquiresIdentity, isNotesPopup, setIsNotesPopup } =
    useContext(UserContext);
  const token = users?.token;

  //  const {uploadedFiles, loading} = useFetchUploadedFiles()
  // console.log(uploadedFiles)
  const files = [
    { id: 1, name: "S.NO", width: 60 },
    { id: 2, name: "Uploaded at", width: 160 },
    { id: 3, name: "Uploaded by", width: 160 },
    { id: 4, name: "Doc Type", width: 160 },
    { id: 5, name: "Doc", width: 200 },
    { id: 6, name: "More Details" },
  ];

  const getPresingnedDocUrl = async (filename) => {
    try {
      const response = await axios.get(
        // `${process.env.REACT_APP_NEW_API_URL}/get-pre-signed-s3-url?identifier=${identity}&filename=${filename}`,
        `${process.env.REACT_APP_NEW_API_URL}/get-pre-signed-s3-url?identifier=${enquiresIdentity}&filename=${filename}`,

        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response, "res");
      if (response.data && response.data.data) {
        console.log("getPresignedDocurl", response?.data?.data);

        return response.data.data;
      }
      // } else {
      //   console.error("Presigned Doc URL not found in the response.");
      //  }
      return null;
    } catch (error) {
      console.error("Error fetching the presigned URL:", error.response.data);
    }
  };

  const downloadFile = async (filename) => {
    const presignedDocUrlResponse = await getPresingnedDocUrl(filename);
    if (!presignedDocUrlResponse) {
      console.error("Presigned Doc URL is null or undefined.");
      return;
    }

    const presignedUrl = presignedDocUrlResponse.url;

    // Download the file
    try {
      const anchor = document.createElement("a");
      anchor.href = presignedUrl;

      // Force download with a filename
      anchor.download = filename;
      document.body.appendChild(anchor);
      anchor.target = "_blank";
      anchor.click();
      document.body.removeChild(anchor);
    } catch (error) {
      console.error("Error during file download:", error);
    }
  };

  const handleViewMore = (file) => {
    console.log(enquiresIdentity, file?.id);
    // navigate(`/admin/patient-list/${file?.id}`, {
    //   state: { file},
    // });
    localStorage.setItem("selectedFile", JSON.stringify(file));
  };

  const openNotesPopup = (file) => {
    setIsNotesPopup(true);
    setFiles(file);
  };

  const closeNotes = () => {
    setIsNotesPopup(false);
  };

  return (
    <div>
      <div className="w-full shadow-sm outline-none border border-gray-300 bg-slate-200 text-gray-900 rounded-lg focus:ring-[#4838C8] focus:border-[#4838C8] block p-3 my-3 ">
        <div
          className="flex justify-between items-center cursor-pointer"
          onClick={() => setIsUplodedVisible((prevState) => !prevState)}
        >
          <p className="font-semibold text-xl">Uploaded Doc</p>
          <div>
            {isUplodedFileVisible ? (
              <svg
                className="w-6 h-6 text-gray-800 "
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m16 14-4-4-4 4"
                />
              </svg>
            ) : (
              <svg
                className="w-6 h-6 text-gray-800 "
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m8 10 4 4 4-4"
                />
              </svg>
            )}
          </div>
        </div>
      </div>
      {isUplodedFileVisible &&
        (loading ? (
          <div>Loading...</div>
        ) : uploadedFiles.length > 0 ? (
          <div className="w-full font-poppins mt-2 mx-auto overflow-x-auto ">
            <table className="w-full border-collapse">
              <thead className="">
                <tr className="">
                  {files.map((file) => (
                    <th
                      key={file.id}
                      className="bg-[#E9F0FF] text-black p-3 text-left text-sm font-medium border border-gray-400"
                      style={{ width: file.width }}
                    >
                      {file.name}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-[#c0c0c0] border-b border-[#c0c0c0] ">
                {uploadedFiles.map((file, index) => (
                  <tr
                    key={file.id}
                    className=" hover:bg-[#DCEFF4] transition-colors   cursor-pointer divide-x divide-[#c0c0c0] border-x border-[#c0c0c0]  rounded-b-md text-start text-sm"
                  >
                    <td className="px-4 py-2">{index + 1}</td>
                    <td className="px-4 py-2">
                      {format(
                        new Date(file.created_at),
                        "dd-MM-yyyy HH:mm:ss"
                      ) + " IST"}
                    </td>
                    <td className="px-4 py-2">{file?.added_by_name}</td>
                    <td className="px-4 py-2">{file?.file_type}</td>
                    <td
                      className="px-4 py-2 capitalize truncate max-w-xs   "
                      onClick={() => downloadFile(file?.file_name)}
                    >
                      <Link
                        to={{}}
                        className="text-blue-500 underline underline-offset-4 hover:text-blue-700"
                      >
                        {file?.file_name}
                      </Link>
                    </td>
                    <td
                      className="px-4 py-2"

                      //   onClick={() => fetchAiSummaries(file)}
                      //onClick={() => fetchAiSummaries(file?.id,file?.file_name)}
                    >
                      <div className="flex sm:flex-row flex-col gap-3 ">
                        <Link
                          to={`/admin/patient/${enquiresIdentity}/${file?.file_id}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-blue-500 underline underline-offset-4 hover:text-blue-700"
                          onClick={() => handleViewMore(file)}
                        >
                          Insights
                        </Link>
                        <span className="mx-2"> | </span>
                        {/* <button
                        className="bg-blue-500 text-white p-2 rounded hover:bg-blue-600 text-sm"
                        onClick={() => openNotesPopup(file)}
                      >
                       View  Notes{" "}
                      </button> */}
                        <Link
                          href="#"
                          onClick={(e) => {
                            e.preventDefault();
                            openNotesPopup(file);
                          }}
                          className="text-blue-500 underline hover:text-blue-700 focus:outline-none flex items-center"
                        >
                          Notes
                        </Link>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <div className="text-center py-5">No files uploaded yet</div>
        ))}
      {isNotesPopup && <NotesPopup closeNotes={closeNotes} file={file} />}
    </div>
  );
};

export default UploadedFiles;
