import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../Context/UserContext";
import { useNavigate } from "react-router-dom";
import FilesUpload from "./FilesUpload";
import UploadedFiles from "./UploadedFiles";
import axios from "axios";
import { format } from "date-fns";

const PatientDetails = ({ closeSidebar }) => {
  const [isEnquiryMessageVisible, setIsEnquiryMessageVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [expandedMessageId, setExpandedMessageId] = useState(null);
  const { users, isSidebarOpen, enquires, enquiresIdentity } =
    useContext(UserContext);

  const navigate = useNavigate();
  const token = users?.token;

  useEffect(() => {
    if (!token) {
      navigate("/login");
    }
  }, [token, navigate]);

  // const enquiryHeaders = [
  //   { id: 1, name: "Name" },
  //   { id: 2, name: "Email ID" },
  //   { id: 3, name: "Phone Number" },
  //   { id: 4, name: "Action" },
  // ];

  const contactFormHeader = [
    { id: 1, name: "Uploaded At" },
    { id: 2, name: "Subject" },
    { id: 3, name: "Clinic_Name" },
    { id: 4, name: "Message" },
  ];

  const toggleReadMore = (id) => {
    setExpandedMessageId((prevId) => (prevId === id ? null : id));
  };

  const getShortMessage = (message, id) => {
    const isExpanded = expandedMessageId === id;
    return isExpanded || message.length <= 40
      ? message
      : `${message.slice(0, 40)}...`;
  };

  const fetchUploadedFiles = async (identity) => {
    if (!identity || !token) return;

    try {
      setLoading(true);
      const response = await axios.get(
        `${process.env.REACT_APP_NEW_API_URL}/get-all-saved-documents?identity=${identity}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      if (response.status === 200) {
        setUploadedFiles(response.data.data.details.documents);
      }
    } catch (error) {
      console.error("Error fetching files:", error);
      if (error.response?.status === 401) {
        navigate("/login");
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUploadedFiles(enquiresIdentity);
  }, [enquiresIdentity]);

  return (
    <div
      className={`fixed top-16 right-0 h-[calc(100vh-4rem)] lg:w-[86%] md:w-[80%] w-[500px] bg-white shadow-lg text-black z-20 transition-transform duration-700 ease-in-out flex flex-col ${
        isSidebarOpen ? "translate-x-0" : "translate-x-full"
      }`}
    >
      <div className="flex-shrink-0 sticky top-0 z-30 bg-white p-4 font-poppins border-b">
        <div className="flex justify-between items-center mb-2">
          <h2 className="text-xl font-medium">Patient Details</h2>
          <button
            onClick={closeSidebar}
            className="p-2 rounded-full hover:bg-gray-100 transition-colors"
            aria-label="Close sidebar"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M4 12h16M13 5l7 7-7 7"
              />
            </svg>
          </button>
        </div>
        <div className="overflow-x-auto">
          <table className="w-full">
            {/* <thead>
              <tr>
                {enquiryHeaders.map((header) => (
                  <th
                    key={header.id}
                    className="bg-[#E9F0FF] text-black p-3 text-left text-sm font-medium border border-[#C0C0C0] font-poppins"
                  >
                    {header.name}
                  </th>
                ))}
              </tr>
            </thead> */}
            <tbody className="bg-white shadow-md divide-y divide-[#C0C0C0] border border-[#C0C0C0]">
              <tr className="divide-x divide-[#C0C0C0] border-x border-[#C0C0C0] rounded-md text-start text-sm">
                <td className="px-3">{enquires.name}</td>
                <td className="px-3">{enquires.email_id}</td>
                <td className="px-3">{enquires.phone}</td>
                <td>
                  <button
                    className="flex justify-center items-center mx-auto p-2 rounded-lg focus:outline-none"
                    aria-label="Edit"
                  >
                    <svg
                      className="w-6 h-6 text-[#4838C8]"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="square"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M7 19H5a1 1 0 0 1-1-1v-1a3 3 0 0 1 3-3h1m4-6a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm7.441 1.559a1.907 1.907 0 0 1 0 2.698l-6.069 6.069L10 19l.674-3.372 6.07-6.07a1.907 1.907 0 0 1 2.697 0Z"
                      />
                    </svg>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="flex-grow overflow-y-auto">
        <div className="px-4 py-4 space-y-4">
          {/* Enquiries Section */}
          <div>
            <button
              className="w-full p-3 bg-slate-200 text-left font-semibold text-xl rounded-lg hover:bg-slate-300 focus:outline-none flex justify-between items-center"
              onClick={() =>
                setIsEnquiryMessageVisible(!isEnquiryMessageVisible)
              }
            >
              <span>Enquiries</span>
              <svg
                className={`w-6 h-6 text-gray-800 transition-transform duration-200 ${
                  isEnquiryMessageVisible ? "rotate-180" : ""
                }`}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m8 10 4 4 4-4"
                />
              </svg>
            </button>

            {isEnquiryMessageVisible && (
              <div className="mt-2 overflow-x-auto">
                <table className="w-full">
                  <thead>
                    <tr>
                      {contactFormHeader.map((header) => (
                        <th
                          key={header.id}
                          className="bg-[#E9F0FF] text-black p-3 text-left text-sm font-medium border border-gray-400"
                        >
                          {header.name}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody className="bg-white shadow-md divide-y divide-[#C0C0C0] border-b border-[#C0C0C0]">
                    {enquires?.child_object?.map((object) => (
                      <tr
                        key={object?.enquiry_child_id}
                        className="divide-x divide-[#C0C0C0] border-x border-[#C0C0C0] rounded-b-md text-start text-sm"
                      >
                        <td className="p-3">
                          {format(
                            new Date(object?.created_at),
                            "dd-MM-yyyy HH:mm:ss"
                          ) + " IST"}
                        </td>
                        <td className="p-3">{object?.subject}</td>
                        <td className="p-3">{object?.clinic_name}</td>
                        <td className="p-3">
                          <div className="space-x-2">
                            <span>
                              {getShortMessage(
                                object?.message,
                                object?.enquiry_child_id
                              )}
                            </span>
                            {object?.message.length > 40 && (
                              <button
                                className="text-[#4838C8] hover:text-[#362ca0] transition-colors whitespace-nowrap"
                                onClick={() =>
                                  toggleReadMore(object?.enquiry_child_id)
                                }
                              >
                                {expandedMessageId === object?.enquiry_child_id
                                  ? "Read Less"
                                  : "Read More"}
                              </button>
                            )}
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
          {/* Uploade Files Component */}
          <div>
            <UploadedFiles uploadedFiles={uploadedFiles} loading={loading} />
          </div>
          {/* FileUploades Component */}
          <div>
            <FilesUpload fetchUploadedFiles={fetchUploadedFiles} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PatientDetails;
